 
.camera-dialog {
    .p-dialog-header {
      display: none !important;
    }
  
    .p-dialog-content {
      padding: 0px !important;
      display: flex;
      overflow: hidden;
      background-color:#000;
    }
  
    video {
      height: 100vh;
      width: 100vw;
    }
  
    img {
      height: auto;
      max-width: 100%;
    }
  
    .camera-container {
      display: flex;
      align-items: center;
    }
  
    .camera-footer-components { 
        position:absolute;
        bottom:0;
        width:100%;
        height:150px;   /* Height of the footer */
        background:#000; 
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }
  
    .camera-button {
      height: 65px !important;
      width: 65px !important;
      border: 0px;
      padding-left: 5px;
    }
  
    .camera-rotate-button {
      background-color: #333;
    }
  
    .camera-close-button {
      background-color: #333;
    } 
  }
  
   
.camera-dialog-white {
  .p-dialog-header {
    display: none !important;
  }

  .p-dialog-content {
    padding: 0px;
    display: flex;
    overflow: hidden;
    background-color:#fff;
  }

  video {
    height: 100vh;
    width: 100vw;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  .camera-container {
    display: flex;
    align-items: center;
  }

  .camera-footer-components { 
      position:absolute;
      bottom:0;
      width:100%;
      height:150px;   /* Height of the footer */
      background:#fff; 
      display: flex;
      align-items: center;
      justify-content: space-evenly;
  }

  .camera-button {
    height: 65px !important;
    width: 65px !important;
  }

  .camera-rotate-button {
    background-color: #333;
  }

  .camera-close-button {
    // background-color: #333;
  }  

  .camera-helper { 
    width: 100vw;
  }
  
  .camera-helper-container {
    margin: auto;
    width: 50%;
  }

}