.CompanyOverviewScreen-filterWrapper {

    .p-invalid.p-component {
        border-color: #f0ad4e;
        border-width: medium;
    }

    .CompanyOverviewScreen-multiSelect {

        &.p-multiselect {
    
            .p-multiselect-label {
                color: black; 
            }
        }
    }
}