table.gantry-box-data {
    font-size: large;
}

table.gantry-box-data > tr > td {
    text-align: left;
    padding-bottom: 15px;
}

table.gantry-box-data > tr > th {
    text-align: left;
    padding-bottom: 15px;
    padding-right: 15px !important;
}