.PriceTrackerScreen {
  
   .p-datatable .p-datatable-tfoot > tr > td {
    padding: 0px !important;
   }

   .bgwhite{
      background-color: #fff;
   }
  
   .DashboardDropdownNivel {
      border: 0px !important;
      font-weight: bold;
      font-size: 1rem;
      color: #333333 !important;
    }
  
    .DashboardDropdownNivel>span { 
      font-weight: bold;
      font-size: 1rem;
      color: #333333 !important;
    }

    .h-100 {
      height: 100% !important;
    }

    .pricetracker-map {
      width: 100%;
      height: 100%;
  }

  .labelgrafica{    
    color: #333333;
    font-size: 18px;
    fill: #333333;
  }
  .p-datatable-tbody tr.trtitle{
    font-size: 11px;
  }
  
  .p-datatable-tbody tr.trtitle span{
    display: inline;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;/* Important for long words! */
  }
  .p-datatable-tbody .headerback{
    background-color: #455471;
    color:#fff!important;
  }
  .p-datatable-thead .unitprice{
    background-color: #E7E6E6;
    color:#000!important;
  }
  .p-datatable-tbody .unitprice{
    background-color: #E7E6E6;
    color:#000!important;
  }
  .p-datatable-thead .tons{
    background-color: #DAE3F3;
    color:#000!important;
  }
  .p-datatable-tbody .tons{
    background-color: #DAE3F3;
    color:#000!important;
  }
  .p-datatable-thead .difference{
    background-color: #FBE5D6;
    color:#000!important;
  }
  .p-datatable-tbody .difference{
    background-color: #FBE5D6;
    color:#000!important;
  }
  .alink{
     text-decoration: underline!important;
     color:#0B5DFF!important;
     cursor: pointer;
  }

  .tblHistoryTooltip{
    border:solid 1px #CDCDCD!important;
  }
  .reddark.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider{
    background:#ae202c!important;
  }
  .black.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider{
    background:#000000!important;
  }
  .blue.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider{
    background:#0582f6!important;
  }
  .green.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider{
    background:#258a50!important;
  }
  .orangee.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider{
    background:#f5a800!important;
  }

  
  .H_ib_content{
    min-width: 6em;
    font: 14px "Lucida Grande", Arial, Helvetica, sans-serif;
    line-height: 16px;
    margin: 5px;
    padding-top: 25px;
    padding-bottom: 15px;
    color: rgba(15,22,33,.8);
    user-select: text;
    -moz-user-select: text;
    -khtml-user-select: text;
    -webkit-user-select: text;
    -o-user-select: text;
    -ms-user-select: text;
  }

  .H_ib_body{
    background: white;
    position: relative;
    bottom: .5em;
    padding: 0;
    right: 0px;
    border-radius: 5px;
    margin-right: -3em;
    box-shadow: 0px 0 4px 0 rgba(15, 22, 33, 0.6);
    margin-bottom: 0.5em;
    text-align: left!important;
  }

  .H_ib_close{
    font-size: .6em;
    position: absolute;
    right: 5px;
    width: 12px;
    height: 12px;
    cursor: pointer;
    top: 5px;
    padding-bottom: 2px;
    z-index: 1;
    background: none;
    box-shadow: none;
  }

  .pointfiltermap{
    color: #333333;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    fill: #333333;
  }
  .pointfiltermapdisabled{
    color: #CCCCCC;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    fill: #CCCCCC;
  }

  .p-dialog-title{
    font-weight: 600;
    font-size: 1rem!important;
  }

  .tblfilter tbody td, thead th{
        padding:0px!important; 
  }
  
}
