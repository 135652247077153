
/* Home Menu Card CSS */
.HomeMenuCard {
    height: 250px;
    padding: 0px;
  } 
  
  .image-menu-card {
    width: 100% !important;
    height: 100% !important;
    color: #fff;  
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    border-radius: 1rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 48px;
    border-right: 2px solid; 
  }

  
  .image-left-card {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .items-menu-card { 
    position: relative;
    min-height: 100%;
    max-height: 100%; 
    overflow-x: hidden;
    overflow-y: auto;
  }
   
  
  .title-menu-card {
    font-size: 24px;
    line-height: 25px;
    font-weight: bold;
    position: sticky;
    top:0;
    background-color: #ffffff;
    z-index: 100;
    padding-bottom: 15px; 
    border-radius: 1rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  } 
  
  
  
  @media (max-width: 576px) {
    .HomeMenuCard {
      height: 100%;
      padding: 0px;
    }
  
    .image-left-card { 
      border-radius: 1rem;  
    }
    
    .image-menu-card { 
      border-right: 0px solid; 
    }
    
    
  }
  
  
  .HomeMenuCard-Other {
    height: 810px;
    padding: 0px;
  }
  
  .image-menu-card-Other {
    width: 100% !important;
    color: #fff;  
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 48px;
  }

  .image-left-card-Other {
    width: 100%;
    border-radius: 1rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .Hr-subtitle {
    font-size: 16px;
    font-weight: bold; 
    color: #CE323A; 
    cursor:pointer;
    text-decoration: underline; 
  }
  
  .borderbottomcard{
    padding-bottom: 2px;
    border-bottom: 1px solid rgb(23, 162, 184);
  }

  
  @media only screen and (max-width: 600px) {
    .borderbottomcard{
      padding-bottom: 2px;
      border-bottom: none;
    }
  }
  .spanunderline:hover { 
    font-weight: bold; 
    cursor:pointer;
    text-decoration: underline; 
  }
  
  
  /***************************************/
  