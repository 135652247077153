.dataview-base .p-dropdown {
    width: 14rem;
    font-weight: normal;
}

.dataview-base .product-name {
    font-size: 14px;
    font-weight: 700;
}

.dataview-base .product-description {
    margin: 0 0 1rem 0;
}

.dataview-base .product-category-icon {
    vertical-align: middle;
    margin-right: .5rem;
}

.dataview-base .product-category {
    font-weight: 600;
    vertical-align: middle;
}

.dataview-base .product-list-item {
    display: flex;
    align-items: center;
    padding: 1rem;
    width: 100%;
}

.dataview-content {
    padding-right: 1rem;  
}


.dataview-base .product-list-item img {
    width: 150px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-right: 2rem;
}

.dataview-base .product-list-item .product-list-detail {
    flex: 1 1 0;
}

.dataview-base .product-list-item .p-rating {
    margin: 0 0 .5rem 0;
}

.dataview-base .product-list-item .product-price {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: .5rem;
    align-self: flex-end;
}

.dataview-base .product-list-item .product-list-action {
    display: flex;
    flex-direction: column;
}

.dataview-base .product-list-item .p-button {
    margin-bottom: .5rem;
}

.dataview-base .product-grid-item {
    /* margin:  .5em; */
    border: 1px solid #dee2e6;
    padding: 10px;
}

.dataview-base .product-grid-item .product-grid-item-top,
    .dataview-base .product-grid-item .product-grid-item-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
 
.dataview-base .product-grid-item .product-grid-item-content {
    text-align: left;
} 

.dataview-base .product-grid-item .product-grid-item-bottom-toolbar {
    text-align: right;
} 

.dataview-base .product-grid-item .product-grid-item-additional-info {
    text-align: left; 
    border-left: 6px solid #dee2e6;  
    padding-left: 3px;
}  


.dataview-base .product-grid-item .product-price {
    font-size: 1.5rem;
    font-weight: 600;
}

.dataview-base .product-grid-item-additional-info .product-label { 
    font-weight: 600; 
    font-size: 10px;
}

@media screen and (max-width: 576px) {
    .dataview-base .product-list-item {
        flex-direction: column;
        align-items: center;
    } 

    .dataview-base .product-list-item .product-list-detail {
        text-align: center;
    }

    .dataview-base .product-list-item .product-price {
        align-self: center;
    }

    .dataview-base .product-list-item .product-list-action {
        display: flex;
        flex-direction: column;
    }

    .dataview-base .product-list-item .product-list-action {
        margin-top: 2rem;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
}


.dataview-base .p-dataview-header {
    padding-top: 3px;
    padding-bottom: 3px;
}

.dataview-base .p-dataview-content {
    padding-top: 15px;
    padding-bottom: 3px;
}

.dataview-base .p-dataview-emptymessage {
    text-align: center;
    font-style: italic; 
}