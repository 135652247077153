.CompanyOverviewOverallStatusChart {

    .p-badge {

        &.CompanyOverviewOverallStatusChart-microstopsBadge {
            background-color: lightgrey;
        }
    }

    .CompanyOverviewOverallStatusChart-label {
        padding: unset;

        span {
            font-size:xx-small;        
        }        
    }

    .CompanyOverviewOverallStatusChart-liveBadge {
        background-color: transparent;
        border-color: grey;
        border-style: solid;
        border-width: thin;
        color: grey;
    }
}