.downtime-score-square-continer { 
    width: auto;
    position: relative;
  }  

  .downtime-score-square {
    height: 75px;
    width: 100%;
    border-radius: 4%;
    font-size: 16px;
    font-weight: bold;
    font-weight:  900 !important;
  } 

  .downtime-square-right-top-badge { 
    position: absolute;
    top:  3px;
    right: 7px;
  }

  .downtime-sqare-badge { 
    width: 20px;
    height: 20px !important;
    padding: 0px !important;
  }

  .downtime-square-check{
    padding-top: 6px;
    font-size: 12px;
    font-weight: bold;
  }

.downtime-score-square-red-gradient {
    background-color: #dd0c1e !important;
    color: #333333 !important; 
    background: linear-gradient(50deg, #dd0c1e 0%, #dd0c1e 88%);
  }

  .downtime-score-square-purple-gradient {
    background-color: #7c0cbd !important;
    color: #333333 !important; 
    background: linear-gradient(50deg, #7c0cbd 0%, #7c0cbd 88%);
  }
  .downtime-score-square-yellow-gradient {
    background-color: #ffbc00 !important;
    color: #333333 !important; 
    background: linear-gradient(50deg, #ffbc00 0%, #ffbc00 88%);
  }
  .downtime-score-square-blue-gradient {
    background-color: #4589f4 !important;
    color: #ffffff !important; 
    background: linear-gradient(50deg, #4589f4 0%, #6da4f6 88%);
  }
  
  .downtime-score-square-green-gradient {
    background-color: #3ad478 !important;
    color: #333333 !important; 
    background: linear-gradient(50deg, #3ad478 0%, #87ef7e 88%);
  }

  .downtime-score-square-strongblue-gradient {
    background-color: #072a61 !important;
    color: #ffffff !important; 
    background: linear-gradient(50deg, #072a61 0%, #072a61 88%);
  }

  .tablet-label-value {
    font-weight: bold;
  }
  
  .Tablet-Field-Read-Only {
    padding: 10px;
    border: 1px dotted #4B5563;
    margin-bottom: 5px;
  }

  .margin-right-5 {
    margin-right: 5px;
  }
  .Tablet-Field {
    padding: 10px;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0.375rem;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
    margin-bottom: 5px; 
  } 
  
  .two-columns-list-button > div {
    display:grid;
    margin-top: 5px !important; 
    margin-right: 5px !important; 
    width: 220px !important; 
  }
  
  .two-columns-list-button  > div.p-button:not(:last-child) {
    border-right: 1px solid #ced4da; 
  }

  .icon-position-bottom-left{
    position: fixed;
    bottom: 40px;
    left: 25px;
    z-index: 20;
  }

  .icon-position-bottom-right{
    position: fixed;
    bottom: 40px;
    right: 25px;
    z-index: 20;
  }
  .icon-style{ 
    border: 2px solid #fff;
    border-radius: 50%;
    height: 50px !important;
    width: 50px !important;
    color: #fff;
    cursor: pointer;
    animation: movebtn 3s ease-in-out infinite;
    transition: all .5s ease-in-out;
  }
  .icon-style:hover{
    animation: none;
    background: #fff;
    color: #551B54;
    border: 2px solid #551B54;
  }

  @keyframes movebtn {
    0%{
      transform: translateY(0px);
    }
    25%{
      transform: translateY(10px);
    }
    50%{
      transform: translateY(0px);
    }
    75%{
      transform: translateY(-10px);
    }
    100%{
      transform: translateY(0px);
    }
  }