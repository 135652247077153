/* CarouselDemo.css */

.Carousel .product-item .product-item-content {
    border: 1px solid var(--surface-d);
    border-radius: 3px;
    margin: .5rem; 
    padding: 1rem 1rem;
    box-shadow: 0 0 20px rgb(0 0 0 / 5%);
    border-radius: 1em;
  }

  .Carousel .product-item-header {
    display: flex; 
  }

  .Carousel .product-item-title {
    font-weight: bold;
    text-align: center;
    width: 100%;
  }
  
  .Carousel .product-item-numer {
    font-weight: bold;
    text-align: center; 
  }
  
  .Carousel .product-item-description {
    padding-top: 25px;
    max-height: 230px;
    min-height: 270px;
    overflow-y: auto;
  }

  .Carousel .li-group-item {
    font-weight: normal !important;
  }