.react-sign-canvas {
    background-color: #fbfcfe;
    border: 1px solid #dbdbdc;
    width: 100%;
    height: 100%;
    height: 152px;
    border-radius: 22px !important;
  }

.Signature {
    position: relative;
}

.Signature-clear {
    position: absolute;
    right: 22px;
    top: 10px;
    color: #ff8248;
    font-weight: 600; 
    cursor: pointer;
    border-bottom: 1px dashed;
}

.react-sign-canvas-disabled {
    background-color: #f2f2f2;
    border: 1px solid #dbdbdc;
    width: 100%;
    height: 152px;
    border-radius: 22px !important;
    cursor:not-allowed;
    text-align: center;
    padding: 10px;
}

.react-sign-canvas-img {  
}

.Signature-keyboard {
    position: absolute; 
    top: 10px;
    left: 22px;
    color: #1c398d;
    font-weight: 600; 
    cursor: pointer;
    border-bottom: 1px dashed;
}

.signature-font{
    font-family: 'Shadows Into Light', cursive;  
    font-size: 30px;
    line-height: 30px;
    height: 152px;
}

.signature-box {
    background-color: #fbfcfe;
    border: 1px solid #dbdbdc;  
    height: auto;
    padding-top: 20px;
  }