.homemenu-tablet-button { 
    width: auto;
    border-radius: 15px; 
}   

.homemenu-tablet-buttonIcon {
    width: 80px;
    height: 80px;
 }

.homemenu-tablet-buttonIcon-fontawesome {
    width: 80px;
    height: 80px;
    font-size: 75px;
    color: #ffffff; 
}

.homemenu-tablet-buttontitle {
   color: #ffffff !important;
   font-size: 18px !important; 
   height: 35px; 
}

.homemenu-checkIn {
    background-color: #23c879 !important; 
    background: linear-gradient(50deg, #23c879 0%, #08824f 88%);
}

.homemenu-changeover {
    background-color: #2484ff !important; 
    background: linear-gradient(50deg, #2484ff 0%, #0d4da4 88%);
}

.homemenu-gantry { 
    background-color: #334c80 !important; 
    background: linear-gradient(50deg, #193a7f 0%, #334c80 88%);
}

.homemenu-gantry-stopped {
    // background-color: #ba2626 !important; 
    // background: linear-gradient(50deg, #ba2626  0%, #ba2626  88%); 
     
    
    animation-name: gantrystopped;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    /*
    animation: awaitingforklift-beating .25s infinite alternate;
    */
} 

@keyframes gantrystopped {
    from {background-color: #ba2626;}
    to {background-color: #ff804d;}
  }

@keyframes gantrystopped-beating{
	to { transform: scale(.98); }
}


.homemenu-checkout {
    background-color: #fe3a3a !important; 
    background: linear-gradient(50deg, #fe3a3a 0%, #ba2626 88%);
}

.homemenu-scrap {
    background-color: #a667ff !important; 
    background: linear-gradient(50deg, #a667ff 0%, #632aab 88%);
}

.homemenu-downtime {
    background-color: #fed44b !important; 
    background: linear-gradient(50deg, #fed44b 0%, #fe9d12 88%);
}

.homemenu-materialrequest {
    background-color: #c18b4c !important; 
    background: linear-gradient(50deg, #c18b4c 0%, #734312 88%);
}

.homemenu-break {
    background-color: #3db0ff !important; 
    background: linear-gradient(50deg, #3db0ff 0%, #1074b2 88%);
}

.homemenu-lunch {
    background-color: #ff804d !important; 
    background: linear-gradient(50deg, #ff804d 0%, #f07143 88%);
}

.homemenu-return-work { 
    background-color: #12A9AD !important; 
    background: linear-gradient(50deg, #12A9AD 0%, #057376 88%);
}

.homemenu-production-stats { 
    background-color: #3dddff !important; 
    background: linear-gradient(50deg, #3dddff 0%, #31b6d2 88%);
}

.homemenu-production-scrap-stats { 
    background-color: #d5005a !important; 
    background: linear-gradient(50deg, #d4558b  0%, #d4558b  88%);
}

.homemenu-production-forklift {
    background-color: #696967 !important; 
    background: linear-gradient(50deg, #696967  0%, #696967  88%); 
}

.homemenu-production-awaitingforklift {
    // background-color: #ba2626 !important; 
    // background: linear-gradient(50deg, #ba2626  0%, #ba2626  88%); 
     
    
    animation-name: awaitingforklift;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    /*
    animation: awaitingforklift-beating .25s infinite alternate;
    */
} 

@keyframes awaitingforklift {
    from {background-color: #ba2626;}
    to {background-color: #ff804d;}
  }

@keyframes awaitingforklift-beating{
	to { transform: scale(.98); }
}

.homebutton-left-boder {
  border-radius: 20px 0px 0px 20px !important;
}

.homebutton-right-boder {
    border-radius: 0px 20px 20px 0px !important;
  }

.homebutton-disclosure-text {
  color: #ba2626;
  font-style: italic;
  font-weight: 600; 
}

.no-cell-configured {
  font-size: 18px;  
}

.homemenu-tablet-buttonIcon-small {
    width: 40px;
    height: 40px;
 }

 
 .tablet-screen {
    .tablet-label-content {
        font-size: 16px;
    }

    .tablet-label {
        font-size: 16px;
    }

    .tablet-label-span {
        font-size: 16px;
    }

    .p-button-label {
        font-size: 16px;
    }

    .p-inputtext {
        font-size: 16px;
    }

    .p-inputtextarea{
        font-size: 16px;
    }

    .hg-button {
        font-size: 16px;
    }
 }