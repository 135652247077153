.login-bg {
  width: 100%;
  height: 100vh;
  position: absolute;
  background-color: #0D2034;
}

.login-logo {
  background-color: white;
  width: 116.5%;
  margin-left: -8%;
  height: 11vh;
  border-radius: 25px 25px 0px 0px;
  margin-top: -9%;
  text-align: center;
}

.login-img {
  margin-top: 3%;
}

.bg-color {
  background-color: #142D47;
}

.login-welcome {
  text-align: center;
  margin-top: 10%;
}

.login-welcome-text {
  color: white;
  font-size: 17pt;
}

.login-form {
  margin-top: 10%;
}

.login-input {
  border-radius: 19px;
  background-color: #0d2034 !important;
  border: 2px solid white !important;
  color: white;
  --text-color: white;  
}

.login-input:focus {
  border-radius: 19px;
  background-color: white !important;
  border: 2px solid white !important;
  color: #0d2034 !important;
  --text-color: #0d2034 !important;  
}

.login-button {
  border-radius: 20px;
  background-color: #20D891;  
  margin-top: 10% !important;
}

.home-card {
  display: inline-block;
  margin-left: 3%;
}

.form-line {

}

.cardbody-color {
  background: linear-gradient(90deg, rgba(20,117,239,1) 0%, rgba(46,199,237,1) 100%);
  color: white;
}

.header-search {
  border-radius: 25px;
  background-color: #435470;
  color: white;
}

.search-header-box {
  height: 40px;
}

.searchSideBarShow {
  display:block;
}

.searchSideBarHidden {
  display:none;
}

.text-area {
  color: #6c757d !important;
}

.text-paragraph {
  white-space: pre-line;
  line-height: 18px;
}