.keyboard-container {
    display: none;
    z-index: 10;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.keyboard-visible  {
    display: block;
}


.keyboard-hidden  {
    display: none;
}