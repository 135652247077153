.dx-column-header {
    background-color:#435470 !important;
    color: #ffffff !important;
}

.dx-header-level-1 {
    background-color:#01b6c3 !important;
    color: #ffffff !important;
}

.dx-header-level-1 .dx-expand { 
    color: #ffffff !important;
}

.dx-header-level-2 {
    background-color:#ebe9eb !important;
    color: #455472 !important;
}
 
.dx-header-level-2 .dx-expand { 
    color: #455472 !important;
}

.dx-header-level-3 {
    background-color:#fffeff !important;
    color: #455472 !important;
}
 
.dx-header-level-3 .dx-expand { 
    color: #455472 !important;
} 

.dx-row-level-1 { 
    background-color:#f0f3f5 !important;
    color: #000000 !important;
}

.dx-cell{
    color: #000000 !important;
}

.dx-area-description-cell {
    background-color:#ffffff !important;
    color: #000000 !important;
}

.dx-total-row { 
    background-color:#f0f3f5 !important;
    color: #000000 !important;
    font-weight: bold;
}

.dx-pivotgrid .dx-pivotgrid-area td { 
    color: #000000;
}

.dx-expand {
    color: inherit !important;
}