.solicitudList-header {
    font-weight: bold;
    font-size: 15px;
}


.solicitudList-body {
    font-weight: bold;
    font-size: 15px;
}

.solicitudList-footer {
    font-weight: bold;
    background-color: rgb(7,31,76);
    color: white;
    font-size: 15px;
}

.solicitudList-dataView {
    min-height: 1000px !important;
}