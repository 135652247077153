.survey-edit-container {
    background: #f4f5f5;
    border: 1px solid #333E48;
    border-radius: 2px;
    background-clip: padding-box; 
    font-size: 13px;
    font-weight: 400;
    color: #333E48;
    width: 100%;
}

.survey-edit-body {
    padding: 10px;
}
.survey-toolbar-options {
    background-color: #ffffff;
    padding: 10px;
}

.survey-edit-label {
    font-weight: bold;
}

.page-container-no-edit {  
    cursor: pointer;
}

.page-container-no-edit:hover { 
    background: #f4f5f5;
    cursor: pointer;
}

.pregunta-container-no-edit {  
    cursor: pointer;
}

.pregunta-container-no-edit:hover { 
    background: #f4f5f5;
    cursor: pointer;
}
.titulo-encuesta {
    background: #CF323A;
}

.survey_question_required .Pregunta { 
    color: #CF323A !important;
}

.CollectorCard {
    min-height: 200px;
    box-shadow: 0 0 8px rgb(0 0 0 / 35%);
}

.CollectorCard:hover {
    box-shadow: 0 0 24px rgb(0 0 0 / 35%); 
} 

.CollectorTitle {
    color: #333e48;
    line-height: 1.5;
    margin: 0;
    font-weight: 300;
    font-size: 34px;
}

.CollectorCardSelected {
    background-color: #fef6d9;
}

.TitleCollectResponses {
    color: #435470;
    font-size: 26px;
    font-weight: bold;
    padding: 32px 0;
    text-align: center; 
}
 

.InstructionsCollectResponses {
    color: #333e48;
    line-height: 1.5;
    margin: 0;
    font-weight: 400;
    text-align: center;
}

.survey-chips > .p-chips-multiple-container{
    min-height: 150px;
    display:block;
}

.survey-card-title {
    color: #6B787F;
    font-size: 12px;
}

.survey-card-result {
    color: #333E48;
    font-size: 26px;
}

.survey-chart-name {
    color: #333E48;
    font-size: 16px;
    font-weight: bold;
}

.survey-title { 
    color: #6B787F;
    font-size: 26px;
}

.p-selectbutton .p-button .buttonListSurvey .p-highlight {
    background: #2196F3;
    border-color: #2196F3;
    color: #ffffff;
}

.buttonListSurvey > div.p-highlight:before {
    content: '\2713';
    display: inline-block;
    color: #ffffff;
    padding: 0 6px 0 0;
}
 
.buttonListSurvey  {
    display: block !important;   
  }

.buttonListSurvey> div {
    display: block !important;   
    margin-top: 5px !important; 
  }
  
.buttonListSurvey > div.p-button:not(:last-child) {
border-right: 1px solid #ced4da;
text-align: left !important;
}

.buttonListSurvey > div.p-button { 
text-align: left !important;
}

.buttonListSurveyInvalid > div.p-button  {
border-color: #f44336 !important;
text-align: left !important;
}

.buttonListSurveyTablet > div.p-button > div.p-button-label  {
   font-size: 50px;
}
    

.surveyNotFoundIntructions { 
    border-radius: 1em;
    background-color: #1c398d;
    color: #ffffff;  
    width: 100%;
    height: auto;
    padding: 5px;
    text-align: center; 
  }

  .surveyNotFoundIntructions > p { 
    font-size: large;
    line-height: 30px;
  }

  .surveyNotFoundIntructionsModal { 
    border-radius: 1em;
    background-color: #ffffff;
    color: #333E48;  
    width: 100%;
    height: auto;
    padding: 5px;
    text-align: center; 
  }

  
  .surveyNotFoundIntructionsModal > p { 
    font-size: large;
    line-height: 30px;
  }
