.solicitud-card {
    min-height: 150px;
}

.solicitud-body {
    font-weight: bold;
    font-size: 15px;
}

.solicitud-footer, .solicitud-header {
    font-weight: bold;
    background: rgb(7,31,76) !important;
    color: white;
    font-size: 15px;
}
