.down-red {
    background: linear-gradient(90deg, DarkRed, #d00000) !important;
    color: white !important;
}

.card-footer {
    color: black;
    border-radius: 0 0 10px 10px !important;
}

.down-yellow{
    background: linear-gradient(90deg, rgb(255,123,0), rgb(255,170,0)) !important;
    color: white !important;
}

.up-green {
    background: linear-gradient(90deg, rgba(5,134,0,1) 0%, rgba(0,180,18,1) 100%) !important;
    color: white !important;
}

.card-header {
    color: black;
    border-radius: 10px 10px 0 0 !important;
}

.card-downtimes {
    border-radius: 10px !important;
    font-weight: bolder !important;
    font-size: 20px !important;
    margin: 3px !important;
}

.card-text-downtimes {
    font-weight: bolder !important;
    font-size: 14px !important;
}
.card-body-downtimes { 
    min-height: 180px !important;
    font-size: 14px !important; 
}

.text-gray {
    color: gray;
}

.text-label {
    font-weight: normal;
    text-decoration: underline;
}

.text-normal {
    font-weight: normal;
}

.details-button {
    background: rgb(17,140,237);
    color: white;
    font-weight: bold;
    border-color: rgb(17,140,237);
    margin-top: 15px;
}

.detail-table-head {
    color: white;
    background-color: rgb(0,52,131);
}

.detail-table {
    font-size: x-large;
}
 