.it-tickets-dataview .p-dataview-content {
    max-height: 28vw !important;
}

.it-card-red {
    background: linear-gradient(90deg, DarkRed, #d00000) !important;
}

.it-card-green {
    background: linear-gradient(90deg, DarkGreen, #11b802) !important;
}

.it-card-orange {
    background: linear-gradient(90deg, DarkOrange, rgb(255, 208, 0)) !important;
}

.it-card-white {
    background: white !important;
}

.it-text-red {
    color: #d00000;
}

.it-text-dark-red {
    color: darkred;
}

.it-text-green {
    color: Green;
}

.it-text-orange {
    color: DarkOrange;
}

// .it-card-info .card-header {
//     background: lightblue;
// }

.it-card-info .card {
    padding: 0px;
}

.it-card-info .card-body {
    margin: 0px;
}