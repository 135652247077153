.CompanyOverviewActiveCellsChart {

    .p-badge {

        &.CompanyOverviewActiveCellsChart-liveBadge {
            background-color: transparent;
            border-color: grey;
            border-style: solid;
            border-width: thin;
            color: grey;
        }
    }
}