@import url('https://fonts.googleapis.com/css?family=Open+Sans|Rock+Salt|Shadows+Into+Light|Cedarville+Cursive');

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

.h-75 {
  height: 75vh;
}

.invisible {
  display: none;
}

table.scroll {
  width: 100%;
  margin-top: 1%;
}

table.scroll input {
  border: none;
  border-bottom: solid thin;
  border-radius: 0px;
  border-color: rgba(29, 140, 248, 0.3);
}

table.scroll tbody,
table.scroll thead {
  display: block;
}

table.scroll tbody {
  height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}

table.scroll tbody > tr > td {
  border: none;
}

tbody td,
thead th {
 /* width: 20%;*/
  padding: 3px;
  /* Optional */
}

.input-group {
  margin-bottom: 0px;
}

.form-group .textarea {
  min-height: 210px;
}

#tablaDeTitulos {
  overflow: auto;
  padding-bottom: 10px;
  max-height: 710px;
}

.cardScroll {
  height: 273px;
  overflow: auto;
  padding-bottom: 10px;
}

#textAreaDescripcion {
  height: 280px;
  overflow: auto;
}

#glosarioTextArea {
  min-height: 0px;
  height: 80px;
  overflow: auto;
}

/*
.list-group-item {
  position: relative;
  display: block;
  padding: 1rem 1rem;
  margin-bottom: -0.0625rem;
  background-color: #27293d;
  border: 0.0625rem solid #31334d;
}
*/
.modal-content {
  background: transparent;
  display: inline;
}

.modal-lg {
  max-width: 50%;
  min-width: 800px;
  height: 600px;
  max-height: 600px;
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-open .modal {
  background-color: rgba(0, 0, 0, 0.6);
}

.ReactTable .rt-thead .rt-resizable-header-content {
  text-align: center;
}

.ReactTable .-pagination .-btn {
  width: 33%;
}

.ReactTable .-pagination .-previous {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: right;
}

.ReactTable .-pagination .-next {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: left;
}

.ReactTable .-pagination .-center {
  flex: 1;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
  padding: 0px;
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background-color: rgb(29, 138, 248, 0.2);
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px; 
  background-color: #F5F5F5; }
  
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);  
  background-color: #4285F4; 
}
  /*
  .scrollbar-primary {
  scrollbar-color: #4285F4 #F5F5F5;
  }
 */
/* width */
/*
::-webkit-scrollbar {
  width: 10px;
}
*/
/* Track */
/*
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}
*/
/* Handle */
/*

::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}


::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  background: #002c6f;
}
*/

/* Handle on hover */
.tab-space {
  padding: 0;
}

.nav-pills:not(.nav-pills-icons):not(.nav-pills-just-icons)
  .nav-item
  .nav-link {
  border-radius: 7.5px;
}

.btn-orange.btn-link {
  color: #e6b325;
}

.ReactTable .-pagination .-btn {
  width: auto;
}

.ddlOptions-noBorder {
  border: none;
  border-bottom: solid thin;
  border-radius: 0px;
  border-color: rgba(29, 140, 248, 0.3);
}

.ddlOptions {
  background-color: #525f7f;
  border: none;
  border-bottom: solid thin;
}

#options {
  background-color: #525f7f;
}

.selectedTableRow {
  border: solid 1px #1d8cf8;
  background-color: rgb(29, 140, 248, 0.15);
}

.main-panel > .navbar {
  /* border-top: solid 2px #1D8AF8; */
  background-color: #1e1e2d;
}

.row {
  display: flex;
  align-items: center;
}

.scrollModal {
  max-height: 435px;
  overflow-y: auto;
  overflow-x: hidden;
}

.round {
  border-radius: 10px;
}

.loginCenter {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 45%;
  height: 55%;
  border-radius: 20px;
  background-color: white;
}

.loginLogo {
  top: 50%;
  left: 35%;
  transform: translate(-50%, -50%);
  padding: 10px;
  position: absolute;
}

.formLogin {
  width: 50%;
  margin-left: 20%;
  margin-top: 12%;
}

.content {
  margin: 10px;
  padding: 10px;
  background-color: transparent;
  height: 100%;
  /* height: 93vh; */
}
 
/*
.card .card-header:not([data-background-color]) {
  background-color: #ff6a00; 
  border-radius: 10px 10px 0px 0px;
}

.card-body {
  border-radius: 0px 0px 10px 10px;
}
*/ 
.card {
  /* border: 1px solid rgba(0,0,0,.125); 
  height: 100% !important; */
  box-shadow: 0 0 8px rgb(0 0 0 / 35%);
  border-radius: 1em;
  /* margin-bottom: 0px; */
}
 
.card-header:not([data-background-color]) {
  background: #435470;
  /* background: linear-gradient(90deg, rgba(11,8,52,1) 0%, rgba(5,47,93,1) 88%); */
  padding-left: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  color: #ffffff; 
}
 

.text-white {
  color: #ffffff;
}


.card-login {
  border-radius: 20px;
  background-color: #fff;
}

.input-group-merge {
  box-shadow: none;
}

/* .kar-input-login,
.kar-input-login:focus {
  background-color: #eeeeee;
} */

.kar-component {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px 30px;
}

.kar-icon-color {
  color: #ef2f40;
}

.dx-field-item-label-text,
.kar-label {
  color: #19314b;
  font-weight: bold;
  font-size: 15px;
  margin: 0px;
}

.kar-btn-login {
  color: #ffff;
  background-color: #ef2f40;
  font-weight: bold;
  font-size: 15px;
  margin: 0px;
}

.kar-btn-login:hover {
  color: #ffff;
  background-color: #19314b;
  font-weight: bold;
  font-size: 15px;
  margin: 0px;
}

.dx-field-item-label-location-top {
  margin: 0px;
}

.kar-label-black {
  color: #000000;
  font-weight: bold;
  font-size: 15px;
}

 
.card-header {
  padding: 5px 10px;
  border-radius: 1em 1em 0 0 !important;
}
/*
.card-body{
   padding: 5px 10px; 
}
*/
.card-title { 
  font-weight: 600;
  font-size: 14px;
  margin: 0px;
}  

.dx-datagrid {
  border-radius: 10px;
}

.dx-datagrid-headers,
.dx-datagrid-header-panel {
  border-radius: 10px 10px 0px 0px; 
}

.dx-datagrid .dx-row > td {
  padding-top: 4px;
  padding-bottom: 4px;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  color: #0556b7;
  /* font-weight: bold; */
  font-size: 12px;
}

body {
 background-color: #f5f5f5 !important;  
}

.footer{
  background-color: #f7f7f8 !important;
  bottom: 0;
  position: absolute;
  width: 90%;
  text-align: right;
}

.bg-header { 
  padding: 5px;
  background: #ffffff !important;
  border-bottom: 1px solid #e5e5e5 !important;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%); 
  position: sticky;
  top: 0;
  z-index: 10;
}
/*
.p-sidebar {
  z-index: 2 !important;
}
*/

.userName {
  color: #525f7f;
}

.Cursor {
  cursor: pointer;
}

.form-control-invalid {
    border-color: #dc3545 !important;
}

.buttonList> div {
  display: block;   
  margin-top: 5px !important; 
}

.buttonList > div.p-button:not(:last-child) {
  border-right: 1px solid #ced4da;
  text-align: left !important;
}

.buttonList > div.p-button { 
  text-align: left !important;
}

.buttonListInvalid > div.p-button  {
  border-color: #f44336 !important;
  text-align: left !important;
}

.questionary-title {
  background-color: #f6f9fc;  
  min-height: 45px;
  color: #8898aa; 
  border-top: 1px solid #e9ecef; 
  border-bottom: 1px solid #e9ecef; 
  font-weight: bold;
}
 

.scroll-y {
  max-height: 200px;
  overflow-y: auto; 
  overflow-x: hidden;
}
 
.cellList> div {
  display: float;   
  margin-top: 5px !important; 
  margin-right: 5px !important; 
  width: 45px; height: 25px; 
}

.cellList > div.p-button:not(:last-child) {
  border-right: 1px solid #ced4da; 
}

.cellListInvalid > div.p-button  {
  border-color: #f44336 !important; 
} 

.scrollbar-primary::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5; }
  
  .scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);  
  background-color: #4285F4; }
  
  .scrollbar-primary {
  scrollbar-color: #4285F4 #F5F5F5;
  }
  
  .scrollbar-danger::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);  
  background-color: #F5F5F5;
  border-radius: 10px; }
  
  .scrollbar-danger::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5; }
  
  .scrollbar-danger::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);  
  background-color: #ff3547; }
  
  .scrollbar-danger {
  scrollbar-color: #ff3547 #F5F5F5;
  }
  
  .scrollbar-warning::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);  
  background-color: #F5F5F5;
  border-radius: 10px; }
  
  .scrollbar-warning::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5; }
  
  .scrollbar-warning::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);  
  background-color: #FF8800; }
  
  .scrollbar-warning {
  scrollbar-color: #FF8800 #F5F5F5;
  }
  
  .scrollbar-success::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);  
  background-color: #F5F5F5;
  border-radius: 10px; }
  
  .scrollbar-success::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5; }
  
  .scrollbar-success::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);  
  background-color: #00C851; }
  
  .scrollbar-success {
  scrollbar-color: #00C851 #F5F5F5;
  }
  
  .scrollbar-info::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);  
  background-color: #F5F5F5;
  border-radius: 10px; }
  
  .scrollbar-info::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5; }
  
  .scrollbar-info::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);  
  background-color: #33b5e5; }
  
  .scrollbar-info {
  scrollbar-color: #33b5e5 #F5F5F5;
  }
  
  .scrollbar-default::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);  
  background-color: #F5F5F5;
  border-radius: 10px; }
  
  .scrollbar-default::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5; }
  
  .scrollbar-default::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);  
  background-color: #2BBBAD; }
  
  .scrollbar-default {
  scrollbar-color: #2BBBAD #F5F5F5;
  }
  
  .scrollbar-secondary::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);  
  background-color: #F5F5F5;
  border-radius: 10px; }
  
  .scrollbar-secondary::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5; }
  
  .scrollbar-secondary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);  
  background-color: #aa66cc; }
  
  .scrollbar-secondary {
  scrollbar-color: #aa66cc #F5F5F5;
  }


  
/*CSS*/
.loader-parent {
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background: #fff;
  opacity: 0.5;
  font-size: 2.7em !important;
}

.loader-content {
  width: 60px;
  height: 60px;
  font-size: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: -30px;
  z-index: 999999;
}

.mswSpinner {
  background-image:url('../img/MSW.png');
  width: 65px;
  height: 60px;
}

html {
  font-family: sans-serif; 
  font-size: 13px;
}

body { 
  line-height:18px;
  color: #333333;
}

.form-group {
  margin-bottom: 7px;
}

.p-panel .p-panel-content {
  padding: 4px; 
  padding-top: 15px; 
}


.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}
.btn-circle.btn-lg {
  width: 50px !important;
  height: 50px !important;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  font-size: 24px;
  line-height: 1.33;
  border-radius: 35px;
}

.p-toolbar {
  background: inherit;
  border: 0px;
  padding: 0px; 
}

.p-sidebar-content {
  padding-top: 5px;
}


.bg-card-header-blue {
  background: #296CCC !important;  
}

.bg-card-header-grid {
  background: white !important;  
  color: #435470 !important;  
}

.bg-card-header-gray {
  background: #EDEDED !important;  
  color: #333 !important;  
}

.bg-card-footer-gray{
  background-color: #EDEDED !important;
  color: #777777 !important;  
}


/* Theme Tabs css */
.p-tabview-nav {
  flex-direction: column !important;
}

@media (min-width: 576px) {
  .p-tabview-nav {
    flex-direction: row !important;
  }
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {   
  color: #6e6c6c;
  padding: 1rem; 
  font-weight: 600;  
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;   
  text-transform:uppercase;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0; 
}
.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: #ffffff;
  border-color: #2791FF;
  color: #6e6c6c; 
  border-width: 0 0 2px 0;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  /* background: #2791FF; */
  border-color: none !important; 
  color: #6e6c6c; 
  font-weight: bold; 
  text-transform:uppercase;
}
 
.p-tabview .p-tabview-nav li.p-highlight a.p-tabview-nav-link::after { 
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #2b90d9;
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  
}

.p-tabview-nav-link span {
  text-align: center !important;
  width: 100%;
}
 
/* With arrow tabs */
 
.card-footer {
  padding: 5px 10px;
}

.card-chart-value {
  font-size: 25px;
  font-weight: bold;
  color: #636465;
}


.light-blue {
  background: #00b7c3 !important;
  color: #ffffff !important;
}
.light-red {
  background: #ef2f40 !important;
  color: #ffffff !important;
}

.border-color-light-green {
  border-color: #5bc78b !important;
}

.light-green {
  background: #5bc78b !important;
  color: #ffffff !important;
}

.light-green-color { 
  color: #5bc78b !important;
}

.light-green-dark {
  background: #3e8c63 !important;
  color: #ffffff !important;
}

.light-green-dark-color {
  background: #3e8c63 !important;
  color: #ffffff !important;
}


.hard-blue {
  background: #435470 !important;
  color: #ffffff !important;
}

.hard-gray {
  background: #6b787f !important;
  color: #ffffff !important;
}

.disabled-gray {
  background: #bdc3c6 !important;
  color: #f8f9f9 !important;
}

.sky-blue {
  background: #457dcc !important;
  color: #ffffff !important;
}

.azul-medio {
  background: #215ABF !important;
  color: #ffffff !important;
}

.azul-fuerte {
  background: #2E4480 !important;
  color: #ffffff !important;
}

.gray {
  background-color: #f5f5f5 !important; 
  background: #f5f5f5 !important;  
 }

.highcharts-credits {
  display: none !important;
}

.header-dashboard {
  background: #ffffff !important;  
  border-left: 3px solid #296ccc;
  border-right: 3px solid #296ccc; 
  padding-top: 10px; 
  padding-bottom: 10px; 
}


.header-dashboard-title {
  font-size: 30px;
  font-weight: bold; 
  color: #636465;
  padding-bottom: 10px;
} 

.header-dashboard-sub-title {
  font-size: 14px; 
  color: #636465;
  text-decoration: underline;
  background-color: #e4e4e4;
  padding-top: 5px; 
  padding-bottom: 5px;
}
 
.header-dashboard-sub-title-2 {
  font-size: 30px; 
  color: #636465;
  background-color: #c8c8c8; 
  padding-top: 5px; 
  padding-bottom: 5px;
  text-align: right;
  padding-right: 5px;
  font-weight: bold;
}


div[data-highcharts-chart] { 
  overflow: visible !important; 
}
/*
.p-datatable-header {
  background: #20d891 !important;
  color: #ffffff !important;
  padding: 4px 14px 4px 14px !important;
}


.p-datatable .p-datatable-thead > tr > th {
  background: #435470  !important;
  color: #ffffff !important;
  padding: 4px 4px 4px 4px !important;
}

.p-datatable .p-datatable-scrollable-header{
  background: #435470  !important;
  color: #ffffff !important;
}

*/

.p-datatable .p-datatable-tbody > tr > td {
  padding: 4px 4px 4px 4px
}

.green{
  color:#4caf50;
}

.red{
  color:#ef2f40;
}
.primary {
  background: #007bff !important;
  color:#ffffff !important;
}
.primary-color { 
  color:#007bff !important;
}
.secondary {
  background: #6c757d !important;
  color:#ffffff !important;
}
.secondary-color { 
  color:#6c757d !important;
}
.success{
  background: #28a745 !important;
  color:#ffffff !important;
}
.success-color{ 
  color:#28a745 !important;
}
.danger{
  background: #dc3545 !important;
  color:#ffffff !important;
}
.danger-color{ 
  color:#dc3545 !important;
}  

.warning{
  background: #ffc107 !important;
  color:#282529 !important;
}
.warning-color{ 
  color:#ffc107 !important;
}

.purple {
  background: #9a2fae !important;
  background-color: #9a2fae !important;
  color: #ffffff;
}

.purple-color {
  color: #9a2fae !important; 
}

.light-green {
  background: #00cc85 !important;
  background-color: #00cc85 !important;
  color: #ffffff;
}

.light-green-color { 
  color: #00cc85 !important;
}

.light-blue {
  background: #4389f5 !important;
  background-color: #4389f5 !important;
  color: #ffffff;
}
.light-blue-color { 
  color: #4389f5 !important;
}

.white{
  background: #fff !important;
  background-color: #fff !important;
  color:#777 !important;
}
.white-color{ 
  color:#fff !important;
}

.border-color-info-color {
  border-color: #17a2b8 !important;
}

.info{
  background: #17a2b8 !important;
  background-color: #17a2b8 !important;
  color:#ffffff !important;
}
.info-color{ 
  color:#17a2b8 !important;
}

.info-dark-color {
  color:#0f7a8a
}

.info-dark{
  background: #0f7a8a !important;
  background-color: #0f7a8a !important;
  color:#ffffff !important;
}

.light{
  background: #e0e0e0 !important;
  background-color: #e0e0e0 !important;
  color:#282529 !important;
}
.light-color{
  color:#e0e0e0 !important;
}
.dark{
  background: #343a40 !important;
  background-color: #343a40 !important;
  color:#ffffff !important;
}
.dark-color{
  color:#343a40 !important;
}

.orange {
  background: #ff8248 !important;
  background-color: #ff8248 !important;
  border: 1px solid #ff8248!important; 
  color: #ffffff;
}

.orange-color {
  color: #ff8248 !important;
}

.orange-dark {
  background: #d04622 !important;
  background-color: #d04622 !important;
  border: 1px solid #d04622!important; 
  color: #ffffff;
}

.orange-dark-color {
  color: #d04622 !important;
}

.light-yellow {
  background: #fcd96f !important;
  background-color: #fcd96f !important;
  color: #343a40;
}
.light-yellow-color { 
  color: #fcd96f !important;
}

.light-gray {
  background: #c0c0c0  !important;
  background-color: #c0c0c0  !important;
  color: #343a40;
}
.light-gray-color { 
  color: #c0c0c0  !important;
}

.cool-gray {
  background: #8894B4  !important;
  background-color: #8894B4  !important;
  color: #ffffff;
}
.cool-gray-color { 
  color: #8894B4  !important;
}

.light-purple {
  background: #3f51b5  !important;
  background-color: #3f51b5  !important;
  color: #ffffff; 
}

.light-purple-color { 
  color: #3f51b5; 
}

.border-color-orange-color {
  border-color: #ff8248 !important;
}


.dark-purple-gradient {
  background-color: #883363 !important;
  color: #ffffff;
  font-weight: bold;
  font-size: 20px;
  background: linear-gradient(90deg, #883363 0%, #223a8b 88%);
}

.annual-icon {
  content : url('../img/Annual_Icon.png');
  width: 40px;
  height: 40px;
}

.plus-icon-middle-right { 
  position:absolute;
  right: -7px;
  top: 50%;
  bottom: 50%;
}

.plus-icon-center { 
  position:absolute;
  left:50%;
  right:50%;
  bottom: -7px;
}

.dashed-border-right {
  border-right: 1px dashed #efefef ;
}

.dashed-border-top {
  border-top: 1px dashed #efefef ;
}

.p-dialog-content {
  overflow-y: inherit;
}

.MuiFormControl-root {
  min-width: 100% !important;
} 

.paddingLeft10{
  padding-left: 10px;
}

.paddignTop10{
  padding-top: 10px;
}

.dialogHeader{
  text-align: center;
}

.dialogHeader h1{
   font-size: 22px;
   color: #777777 !important;
}

.bg-title {
  padding: 5px;
  background: #d0323a !important;
  border-bottom: 1px solid #d0323a !important;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  /*
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
  */ 
  padding: 5px;
  padding-left: 20px;
  font-size: 14px;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.5;
  color: #ffffff;

}

.main-content {
  min-height: 96vh;
  display: flex;
  flex-direction: column;
}

.main-content-layout {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: '1 1 0'
}

.copyright {
  font-size: .75rem;
  line-height: 1.8;
  color: #2e465c;
  text-align: right;
  padding-right: 20px;
}

label {
  color: #6c757d !important;
} 

.p-table-reorderablerow-handle {
  cursor:grab;
} 

.no-border {
  border: 0px !important;
}

.panel-skyblue {
  border-radius: 22px !important;
}
.panel-skyblue>.p-panel-header  {
  background-color: #e4e7f0 !important ;
  border-bottom: none !important;
  border-top-left-radius: 22px !important;
  border-top-right-radius: 22px !important;
  color: #313485 !important;
  font-size: 18px;
}

.panel-skyblue>.p-panel-header>.p-panel-icons>.p-panel-header-icon  { 
  color: #313485 !important;
}

.panel-skyblue>.collapsed-header {
  border-bottom-left-radius: 22px !important;
  border-bottom-right-radius: 22px !important;
}

.panel-skyblue>.p-toggleable-content>.p-panel-content {
  background-color: #e4e7f0 !important ;
  border-bottom-left-radius: 22px !important;
  border-bottom-right-radius: 22px !important;
  padding-left: 40px;
  padding-right: 40px;
}

.panel-white {
  border-radius: 22px !important;
}
.panel-white>.p-panel-header  {
  background-color: #ffffff !important ;
  border-bottom: none !important;
  border-top-left-radius: 22px !important;
  border-top-right-radius: 22px !important;
  color: #274393 !important;
  font-size: 18px;
}

.panel-white>.p-panel-header>.p-panel-icons>.p-panel-header-icon  { 
  color: #274393 !important;
}

.panel-white>.collapsed-header {
  border-bottom-left-radius: 22px !important;
  border-bottom-right-radius: 22px !important;
}

.panel-white>.p-toggleable-content>.p-panel-content {
  background-color: #ffffff !important ;
  border-bottom-left-radius: 22px !important;
  border-bottom-right-radius: 22px !important;
  padding-left: 40px;
  padding-right: 40px;
}

.rounded-button {
  border-radius: 1rem !important;
}

.p-datatable-header .rounded-button {
  border-radius: 4px !important;
}

.center-badge {
  text-align: center;
  position: relative;
  top: -7px;
}

.score-badge {
  padding-left: 15px;
  padding-right: 15px;
  background-color: #ffffff;
  border: 1px solid #999999;
  color: #999999;
  font-weight: bold;
} 

.Respuesta { 
  padding-top: 15px;
  font-weight: 14px;
  white-space: pre-line;
}

.questionario-viewer {  
  overflow-y: auto; 
}  


.p-steps .p-steps-item .p-menuitem-link {
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 3px;
  background: transparent;
}


.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  color: #495057;
  border: 1px solid #e9ecef;
  background: #ffffff;
  min-width: 3rem;
  height: 3rem;
  line-height: 2rem;
  font-size: 1.143rem;
  z-index: 1;
  border-radius: 50%;
  font-weight: bold;
}

 
.p-steps .p-steps-item:before {
  content: " ";
  border-top: 2px dotted #dee2e6;
  width: 100%;
  top: 50%;
  left: 0;
  display: block;
  position: absolute;
  margin-top: -1rem;
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 600;
  color: #1a1a4a;
}

.p-steps .p-steps-item.p-highlight .p-steps-number{
  background: #20d891;
  color: #fff;
  font-weight: bold;
} 


.p-steps .p-steps-item.itemInicio:before{
  content: " ";
  border-top: 2px dotted #dee2e6;
  width: 100%;
  top: 50%;
  left: 50%;
  display: block;
  position: absolute;
  margin-top: -1rem;
}

.p-steps .p-steps-item.itemFinal:before{
  content: " ";
  border-top: 2px dotted #dee2e6;
  width: 50%;
  top: 50%;
  left: 0%;
  display: block;
  position: absolute;
  margin-top: -1rem;
}


@media screen and (max-width: 640px) {
  .p-steps { 
      height: auto;
  }
  .p-steps > ul {
      flex-direction: column;
      height: 100%;
  }
  .p-steps > ul .p-steps-item {
      flex-direction: column-reverse;
      align-items: flex-start;
      justify-content: center;
  }
  .p-steps > ul .p-steps-item:before {
      position: static;
      left: auto;
      top: auto;
      margin-top: 0;
      border-left: 2px dotted var(--surface-d);
      border-top: 0 none;
      width: auto;
      height: 15px;
      margin-left: 1.4rem;
  }
  .p-steps > ul .p-steps-item .p-menuitem-link {
      flex-direction: row;
      overflow: visible;
  }
  .p-steps > ul .p-steps-item .p-menuitem-link .p-steps-title {
      margin: 0 .5rem 0;
  }
  .p-steps > ul .p-steps-item:last-child {
      flex-grow: 0;
  }
  .p-steps > ul .p-steps-item:last-child .p-menuitem-link {
      padding: 0;
  }
  .p-steps > ul .p-steps-item:last-child:before {
      display: none;
  }

  
.p-steps .p-steps-item.itemInicio:before{
  position: static;
  left: auto;
  top: auto;
  margin-top: 0;
  border-left: 2px dotted var(--surface-d);
  border-top: 0 none;
  width: auto;
  height: 15px;
  margin-left: 1.4rem;
}

.p-steps .p-steps-item.itemFinal:before{
  position: static;
  left: auto;
  top: auto;
  margin-top: 0;
  border-left: 2px dotted var(--surface-d);
  border-top: 0 none;
  width: auto;
  height: 15px;
  margin-left: 1.4rem;
}

}

.label {
  white-space: pre-line;
}

label {
  white-space: pre-line;
}
/*
.p-datatable .p-datatable-header{ 
  border-width: 0; 
  border-radius: 4px 4px 0px 0px;
}

.p-datatable .p-datatable-thead > tr > th {
  border: 0px !important;
}


.p-datatable .p-datatable-scrollable-header {
  background: #20d891 !important;
} 
*/

.right-top-badge { 
  position: absolute;
  top: -10px;
  right: 10px;
}

.right-top-tag {
  position: absolute;
  top: -5px;
  /* right: 10px; */
}

.white-badge { 
  background-color: #ffffff;
  border: 1px solid #999999;
  color: #999999;
  font-weight: bold;
  display: inline-block;
  border-radius: 50% !important;
  text-align: center !important;
  width: 23px;
  height: 23px;
  /* padding: 0 .5rem; */
}  

.relative {
  position: relative;
} 


.react-sign-canvas {
  background-color: #fbfcfe;
  border: 1px solid #dbdbdc;
  width: 100%;
  height: 100%;
  border-radius: 22px !important;
}
 
.datatable-custom.p-datatable .p-datatable-thead > tr > th {
  display: none;
}
 
.datatable-custom .p-datatable-scrollable-body{
  overflow-x: hidden!important;
  position: relative!important;
}

.datatable-custom.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover{
  background: transparent;
  color: #495057;
}

.datatable-custom.p-datatable-hoverable-rows .p-selectable-row{
  cursor: auto;
}

.datatable-custom.p-datatable .p-datatable-tbody > tr > td {
  border-bottom: none !important;
}

.UpperCase {
  text-transform: uppercase !important;
}

.p-button.btn-radius.p-button-icon-only{
  width: 2.357rem;
  padding: 0.5rem 0;
  border-radius: 5px;
}

.border-left{
  border-left: 1px solid #cdcdcc!important;
}

.p-avatar.ex-large.p-avatar-xl{
  width: 6.5rem;
  height: 6.5rem;
  font-size: 2.5rem;
}

.RowBody{
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.5rem;
}

.p-tooltip{
  position: absolute;
  padding: .25em .5rem;
  max-width: 20rem!important;
}

.hidebutton .p-splitbutton-defaultbutton{
  display: none;
}

.hidebutton .p-splitbutton-menubutton{
  background: orange !important;
  border: none !important;
}

.hidebutton .p-button-icon-only{
  border-radius: 50%;
  height: 2.357rem;
}

.p-progressbar.danger-color .p-progressbar-value{
  border: 0 none;
  margin: 0;
  background: #dc3545 !important;
}

.p-progressbar.orange-color .p-progressbar-value{
  border: 0 none;
  margin: 0;
  background: #ff8248 !important;
}

.p-progressbar.warning-color .p-progressbar-value{
  border: 0 none;
  margin: 0;
  background: #ffc107 !important;
}

.p-progressbar.success-color .p-progressbar-value{
  border: 0 none;
  margin: 0;
  background: #8FCD78 !important;
}

.rowpadding{
  padding: 0px 14px 12px 15px
}
.borderleftinfo{
  border-left: solid 6px#17a2b8 !important;
}
.cardnotradius.card{
      height: 100% !important;
      box-shadow: 0 0 8px rgb(0 0 0 / 35%);
      border-radius: 0; 
}
.cardnotradius{
      border-radius: 0 !important;
}

.border-bottom{
  border-bottom: 1px solid #cdcdcc!important;
}
.border-bottom-red{
  border-bottom: 1px solid #DD4F42!important;
}
.border-bottom-dashed{
  border-bottom: 1px dashed #cdcdcc!important;
}

.datatable-custom.p-datatable .p-datatable-footer{
  background: #fff;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}


.datatable-score .p-datatable-header {
  background: #fff !important;
  color: #495057 !important;
  border-bottom: 1px solid #E6E5E6;
  border-width: 1px 0 1px 0!important;
  padding: 0.5rem 0.5rem!important;
  font-weight: 600;
  border-left: 6px solid #435470 !important;
}

.p-datatable.datatable-score .p-datatable-tbody > tr > td{
  text-align: left;
  border: 1px solid #E6E5E6;
  padding: 0.5rem 0.5rem!important;
  border-width: 0 0 1px 0;
  padding: 0.5rem 0.5rem;
  outline-color: #a6d5fa;
}

.p-datatable.datatable-score .p-datatable-header{
  border-width: 0;
  border-radius: 0px;
}

.p-datatable.datatable-score .p-datatable-thead > tr > th{
  text-align: left;
  padding: 1rem 1rem;
  border: 2px solid #777;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #495057;
  background: #f8f9fa;
  color: #ffffff !important;
  padding: 0px !important;
}

.p-datatable.datatable-score .p-datatable-thead{
  background: #fff !important;
  color: #495057 !important;
  border-bottom: 1px solid #E6E5E6;
  border-width: 1px 0 1px 0!important;
  padding: 0.5rem 0.5rem!important;
  font-weight: 600;
  border-left: 6px solid #435470 !important;
}

.p-datatable.datatable-score .p-datatable-thead > tr > th{
  background: #fff  !important;
  color: #777 !important;
  padding: 4px 14px 4px 19px !important;
}

.p-datatable.datatable-score .p-datatable-footer{
  background: #fff;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}

.datatable-score.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover{
  background: transparent;
  color: #495057;
}

.datatable-score.p-datatable-hoverable-rows .p-selectable-row{
  cursor: auto;
}

.invalid-color {
  color:#f44336 !important;
} 

.score-color-green { 
  color: #3ad478 !important;  
}

.score-color-yellow { 
  color: #ffbc00 !important;  
}

.score-color-red { 
  color: #f35f3e !important;  
}
 
.bodynotscroll{
  overflow-y: hidden!important;
}

.p-sidebar-lg .p-sidebar-content{
  position: relative;
  overflow-y: auto;
  overflow-x: hidden!important;
}

.p-inplace .p-inplace-display{
  padding: 0rem !important;
  border-radius: 3px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.spanController{
  transition-duration: 0.2s;
  padding-left: 5px;
  font-size: 12px;
  pointer-events: none;
  transition-property: all;
  transition-timing-function: ease;
  line-height: 1;
  color: #6c757d;
}

.default {
  background: #9A9A9B !important;
  color:#ffffff !important;
}
.default-color { 
  color:#9A9A9B !important;
}

.purple {
  background: #A935E5 !important;
  color:#ffffff !important;
}
.purple-color { 
  color:#A935E5 !important;
}

.swal-overlay{
  z-index: 99999999 !important;
}

.p-sidebar-header{
  display: block!important;
      /* align-items: center; */
    /* justify-content: flex-end; */
}

.p-dialog-content { 
  padding-left: 1rem !important; 
  padding-right: 1rem !important; 
}

.p-dialog-header{
  border-bottom: 0 none;
  background: #ffffff;
  color: #495057;
  padding: 1rem !important; 
  border-top-right-radius: 3px;
  border-top-left-radius: 3px; 
  border-top: 7px solid #ffc107;
}

.swal-modal {
  border-top: 7px solid #ffc107 
}

.displayNot{
  display: none!important;
}

.p-fileupload .p-fileupload-content{
  background: #ffffff;
  padding: 0.5rem 1rem;
  border: 1px solid #dee2e6;
  color: #495057;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.colHeaderLeft{
  border-left: 6px solid #435470 !important;
  padding: 4px 14px 4px 30px !important;
}

.colHeaderRight{
  padding: 4px 6px 4px 19px !important;
}

.p-datepicker{
  width: auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999999!important;
}

.p-dialog.p-grid {
  flex-wrap:nowrap
}
.cardh{
  min-height: 120px;
  max-height: 120px;
}
 
.containerChart {
  width: 250px; 
}

.bold{
   font-weight: bold;
}

.p-dataview-content{
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 400px;
  min-height: 410px;
  padding-right: 15px;
}

.dataview-list .product-name {
    font-size: 1.5rem;
    font-weight: 700;
}

.dataview-list .product-description {
    margin: 0 0 0 0;
}

.dataview-list .product-category-icon {
    vertical-align: middle;
    margin-right: .5rem;
}

.dataview-list .product-category {
    font-weight: 600;
    vertical-align: middle;
}

.dataview-list .product-list-item {
    display: flex;
    align-items: center;
    padding: 0.2rem;
    padding-right: 15px;
    width: 100%;
}

.dataview-list .product-list-item img {
    width: 150px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-right: 2rem;
}

.dataview-list .product-list-item .product-list-detail {
    flex: 1 1 0;
}

.dataview-list .product-list-item .p-rating {
    margin: 0 0 .5rem 0;
}

.dataview-list .product-list-item .product-price {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: .5rem;
    align-self: flex-end;
}

.dataview-list .product-list-item .product-list-action {
    display: flex;
    flex-direction: column;
}

.dataview-list .product-list-item .p-button {
    margin-bottom: .5rem;
}

.dataview-list .product-grid-item {
    margin: .5em;
    border: 1px solid #dee2e6;
    padding: 2rem;
}

.dataview-list .product-grid-item .product-grid-item-top,
    .dataview-list .product-grid-item .product-grid-item-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dataview-list .product-grid-item img {
    width: 75%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin: 2rem 0;
}

.dataview-list .product-grid-item .product-grid-item-content {
    text-align: center;
}

.dataview-list .product-grid-item .product-price {
    font-size: 1.5rem;
    font-weight: 600;
}

@media screen and (max-width: 576px) {
    .dataview-list .product-list-item {
        flex-direction: column;
        align-items: center;
    }

    .dataview-list .product-list-item img {
        width: 75%;
        margin: 2rem 0;
    }

    .dataview-list .product-list-item .product-list-detail {
        text-align: center;
    }

    .dataview-list .product-list-item .product-price {
        align-self: center;
    }

    .dataview-list .product-list-item .product-list-action {
        display: flex;
        flex-direction: column;
    }

    .dataview-list .product-list-item .product-list-action {
        margin-top: 2rem;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
}
.btn-min-width {
  min-width: 110px;
} 

.tabs-reverse {
  flex-direction: row-reverse !important;
} 

.FaseTerminada{
  border-left: 6px solid #00cc85 !important;
  padding: 4px 14px 4px 30px !important;
}

.FaseEnProgreso{
  border-left: 6px solid #343a40  !important;
  padding: 4px 14px 4px 30px !important;
}

.FaseNoIniciada{
  border-left: 6px solid #007bff !important;
  padding: 4px 14px 4px 30px !important;
}

.FaseDefault{
  border-left: 6px solid #435470 !important;
  padding: 4px 14px 4px 30px !important;
} 


.title {
  font-size: 22px;
  font-weight: bold;  
}

.sub-title {
  font-size: 16px;
  font-weight: bold; 
  color: #6c757d;  
}

.toggle-switch{
  position: relative;
  margin-right: 0px!important;
  padding-right: 0px!important;
  width: 75px;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
}

.bottom-rounded {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
} 

.p-dialog-content {
  overflow-y: auto;
  overflow-x: hidden;
}

.dialog-header-background-blue>.p-dialog-header {
  background-color: #20378e !important;
  border-top: 0px; 
  color: #ffffff !important;
}

.overflow-ellipsis {
  white-space: nowrap;  
  overflow: hidden;
  text-overflow: ellipsis;  
}

.border-none {
  border: 0px;
}

.background-none {
  background: none;
  background-color: none;
}

.hide_dropdown_btn > .p-dropdown-trigger {
  display: none;
}

.btn-shadow {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)
}

.dialog-content-transparent {
  background: transparent !important;
}

.dialog-transparent {
  box-shadow: none !important;
}

.dialog-transparent > .p-dialog-header {
  display: none !important;
}

.fa-20x {
  font-size: 20em;
}

.no-round {
  border-radius: 0px !important;
}

.no-round > input { 
  border-radius: 0px !important;
}

.no-round-btn-icon > .p-datepicker-trigger{
  border-radius: 0px !important;
}

.p-fluid {
  width: 100% !important;
}
 
.TreeListView .dx-treelist-rowsview {
  border: 1px solid #ccc; 
  border-top: 0px;
}

.TreeListView .dx-treelist-content .dx-treelist-table .dx-row > td.dx-cell-modified:not(.dx-field-item-content):not(.dx-validation-pending), .dx-treelist .dx-treelist-content .dx-treelist-table .dx-row > td.dx-treelist-invalid:not(.dx-field-item-content):not(.dx-validation-pending):not(.dx-treelist-select-all), .dx-treelist .dx-treelist-content .dx-treelist-table .dx-row 
> td:not(.dx-validation-pending):not(.dx-treelist-select-all) {
  padding-left: 4px;
  padding-right: 4px;
}

.TreeListView .dx-toolbar-items-container {
  height: 56px;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  background-color: transparent;
}

textarea.p-disabled, textarea.p-disabled { 
  pointer-events: all;
}

.p-editable-column {
  height: 1px;
}

.p-invalid-column {
  border-color: #f44336;
  border-bottom: 1px solid #f44336; 
  width: 100%;
  height: 100%; 
}

.p-invalid-tree {
  border-color: #f44336;
}

.p-invalid-datatable {
  border-color: #f44336 !important;
}

.boc-light {
  background-color: #e4e7f0 !important;
}

.boc-light > div> hr{
  margin-top: 7px !important;
  margin-bottom: 7px !important;
} 

.p-invalid-fileupload {
  border: 1px solid #f44336 !important;
}

.treeList {
  width: auto!important;
  box-shadow: 0 0 8pxrgb(0 0 0/35%);
  border-radius: 1em 1em 1em 1em;
  overflow: hidden;
}

.treeList>.dx-treelist-container>.dx-treelist-headers>.dx-treelist-content .dx-treelist-table .dx-row 
  > td:not(.dx-validation-pending):not(.dx-treelist-select-all) { 
    padding: 4px !important; 
} 

.video-visor > .player-wrapper {
  padding-top: 0px;
}

.border-gray {
  border-color: gray !important;
}

.small-tag {
  font-size: 10px;
  height: 14px;
  padding: 3px;
}

.hg-theme-default .hg-button span {
  font-weight: bold !important;
}

.p-selectbutton .p-button .buttonListSurveyTablet .p-highlight {
  background: #2196F3;
  border-color: #2196F3;
  color: #ffffff;
}

.buttonListSurveyTablet > div.p-highlight:before {
  content: '\2713';
  display: inline-block;
  color: #ffffff;
  padding: 0 6px 0 0;
}

.buttonListSurveyTablet  {
  display: block !important;   
}

.buttonListSurveyTablet> div {
  display: block !important;   
  margin-top: 5px !important; 
}

.buttonListSurveyTablet > div.p-button:not(:last-child) {
border-right: 1px solid #ced4da;
text-align: left !important;
}

.buttonListSurveyTablet > div.p-button { 
    text-align: left !important; 
    padding-top: 15px;
}

.buttonListSurveyTabletInvalid > div.p-button  {
border-color: #f44336 !important;
text-align: left !important;
}

.buttonListSurveyTablet > div.p-button > .p-button-label  {
 font-size: 22px;
 line-height: 25px;
}

.btntablet {
  padding: 15px;
}

@media (max-width: 600px) {
  .form-group {
    font-size: 16px;
    line-height: 20px;
  }
}




.divMap{
  top:0;
  left: 0;
}

.buttonmap{
  position: absolute;
  z-index:1;
  background-color: #F7F5F7;
  min-height: 35px;
}

.dialogMap .p-dialog-content{
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  overflow-y: hidden!important;
}

.Cursor{
  cursor: pointer;
}

button.gm-control-active.gm-fullscreen-control{
  display: none;
}

.p-inputswitch-checked.checksuccess .p-inputswitch-slider{
 background: #28A745!important;
}

.p-inputswitch-checked.checkdanger .p-inputswitch-slider{
  background: #DC2626!important;
 }
 
 .p-inputswitch-checked.checkinfo .p-inputswitch-slider{
  background: #2563EB!important;
 }
 .p-inputswitch-checked.checkinfoDelayed .p-inputswitch-slider{
  background: #FFFF00!important;
 }

 .content-no-margin {
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
 }

 @media (min-width: 1200px) {
  .sidenav:hover {
    max-width: 300px !important;
  }
  .sidenav .sidenav-toggler {
    padding: 1.5rem;
  }
}

.link-text {
  cursor: pointer;
  text-decoration: underline;
}

.white-card-header {
  background: #ffffff !important; 
  border-bottom: none;
  color: #333 !important;
}

/* Devextreme gantt toolbar button */

.p-button-rounded.devextreme-gantt-toolbar-button {
  padding: 18px;
}

.p-button-rounded.devextreme-gantt-toolbar-button:active {  
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.p-button-rounded.devextreme-gantt-toolbar-button:hover {  
  background-color: rgba(0, 0, 0, 0.08) !important;
} 

.p-button-rounded.devextreme-gantt-toolbar-button .p-button-icon {  
  font-size: 24px;
  color: #778fb2;
} 

.comment-section{
  background: #fafafa !important;
  background-color: #fafafa !important;
  color:#282529 !important;
}
.dialog-header-background-blue .p-dialog-header-icon {
    color: #fff !important;
} 

.dialog-header-background-blue .p-dialog-header-icon:hover {
  color: #333 !important;
} 

.p-treetable ::-webkit-scrollbar {
  background-color: #fff !important;
}

.p-treetable { 
  border-radius: 7px 7px 7px 7px;
  border: 1px solid #ccc; 
  overflow: hidden;
} 


.p-treetable .p-treetable-header{ 
border-width: 0; 
}

.p-treetable .p-treetable-thead > tr > th {
border: 0px !important;
 /* font-size: 11px; */
}

.p-treetable .p-treetable-header {
  background: #20d891;
  color: #ffffff;
  padding: 4px 14px 4px 14px !important;
  border-radius: 7px 7px 0px 0px;
}


.p-treetable .p-treetable-thead > tr > th { 
  background: #435470 !important;
  color: #ffffff !important; 
  padding: 4px 4px 4px 4px !important;
}

.p-treetable .p-treetable-scrollable-header{
  background: #435470;
  color: #ffffff !important;
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
  height: auto !important;
}

.p-treetable .p-treetable-tbody > tr > td {
  padding: 0px !important;
}

/* Apply grid lines to the TreeTable */
.p-treetable .p-treetable-tbody > tr > td {
    border-right: 1px solid #e9ecef !important; /* Change '#ddd' to your preferred color */
}

/* Optional: Add grid lines between TreeTable headers */
.p-treetable .p-treetable-header {
    border-bottom: 1px solid #ddd;
}

/* Optional: Add grid lines around the entire TreeTable */
.p-treetable {
    border-collapse: collapse;
    border: 1px solid #ddd;
}

/* Apply striped background colors to the TreeTable rows */
.p-treetable .p-treetable-tbody > tr:nth-child(odd) > td {
  background-color: #f9f9f9; /* Light gray for odd rows */
}

.p-treetable .p-treetable-tbody > tr:nth-child(even) > td {
  background-color: #ffffff; /* White for even rows */
}

/* Optional: Add hover effect to rows */
.p-treetable .p-treetable-tbody > tr:hover > td {
  background-color: #e0e0e0; /* Light gray for hovered rows */
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  margin: 0px !important;
}

.table-header-background-ligth-green .p-treetable-thead>tr>th, .table-header-background-ligth-green.p-treetable .p-treetable-scrollable-header {
  background-color: #00ba91;
  color: #fff !important;
}
.p-treetable-thead>tr>th.table-header-background-ligth-green {
  background-color: #019e8a !important;
  color: #fff !important;
}


.table-header-background-ligth-orange .p-treetable-thead>tr>th, .table-header-background-ligth-orange.p-treetable .p-treetable-scrollable-header {
  background-color: #D55400;
  color: #fff !important;
}
.p-treetable-thead>tr>th.table-header-background-ligth-orange {
  background-color: #D55400 !important;
  color: #fff !important;
}

.card-message-no-data-found
{
  background-color: #DDEBF7!important;
  border-radius: 0.8em!important;
  padding-top: 10px;
  padding-bottom: 5px;
  text-align: center;
  font-size: 14px;
  box-shadow: 0 0 2px #00000059;
}