.MTDScreen {
    
    margin:  0!important;
    padding:  0!important;

    .table-header-background-blue .p-datatable-thead > tr > th{
        background-color: #455471;
        /* background: linear-gradient(50deg, #019e8a 0%, #3ad478 88%) !important; */
        color: #ffffff !important;
        border: solid 1px #fff!important;
    }

    .rowNotMargin{
        margin: 0;
        padding: 0;
    }

    .titlefilter{
        color: #000000;
        font-weight: bold;
        padding-bottom: 2px;
    }
    .subtitlefilter{
        color: #787878;
        font-weight: bold;
        padding-bottom: 2px;
    }
    .filterTemplate{ 
        position: sticky;
    }
    .p-scrollpanel {
        height: 100%;
        .p-scrollpanel-content {
            padding-bottom: 0;
        }
    }
    .filter-footer{
        background-color: #F5F5F5!important;
        position:-webkit-sticky; 
        position:sticky; 
        bottom: -18px !important;
    }

    .bol{
        border:1px solid red;
        background-color: red;
        height: 2px;
        /* border-radius 50% will make it fully rounded. */
        border-radius: 50%;
        -moz-border-radius:50%;
        -webkit-border-radius: 50%;
        width: 2px;
    }

    .bgwhite{
        background-color: #fff;
    } 
    .titlecolumn{
        padding-top: 10px;
        padding-left: 10px;
        font-weight: bold;
    }


    .backgwhite2{
        padding: 5px;
     }
     .padding5{
         padding: 5px;
     }
     .titleheader{
        font-weight: 600;
        font-size: 14px;
        margin: 0px;
        font-weight: bold;
     }

    .card-chart{
        background-color: #F2F2F2!important;
    }
    .card-chart .bottomdiv{
        text-align: center;
        position:absolute;
        font-weight: bold;
        bottom: 0;
        padding-bottom: 20px;
        font-size: 36px;
        color:#21385C;
        width: 100%;
    }
   
    .card-chart-two{
        background-color: transparent!important;
        box-shadow:none;
        border-radius: 0;
        border: none;
    }
    
    .card-chart-two .card-body{
        flex: 1 1 auto;
        min-height: 1px;
        padding: 0.5rem;
    }


    .card-chart-three{
        background-color: transparent!important;
        box-shadow:none;
        border-radius: 0;
        border: none;
         margin-bottom: 0px; 
    }
 
    .card-one{
        min-height: 140px;
        max-height: 140px; 
        border-radius: 0; 
        margin-bottom: 0px;
        box-shadow:none!important;
    }
    .card-one .card-body{
        flex: 1 1 auto;
        min-height: 1px;
        padding: 1rem;
    }
    .card-one .card-header{
        background: #fff;
        padding-left: 10px;
        padding-top: 2px;
        padding-bottom: 2px;
        color: #000;
        border-radius:0!important;
    }
    .card-one .card-body{
        flex: 1 1 auto;
        min-height: 1px;
        padding: 1rem;
    }
    .card-one .card-title{
        font-weight: 600;
        font-size: 14px;
        font-weight: bold;
    }
    .card-one .bottomdiv{
        text-align: center;
        position:absolute;
        font-weight: bold;
        bottom: 0;
        padding-bottom: 20px;
        font-size: 36px;
        color:#21385C;
        width: 100%;
    }
    .card-one .bottomOntime{
        text-align: center;
        position:absolute;
        font-weight: bold;
        bottom: 0;
        padding-bottom: 45px;
        font-size: 36px;
        color:#21385C;
        width: 100%;
    }
    
    .card-one .bottomOntimefooter{
        text-align: center;
        position:absolute;
        font-weight: bold;
        bottom: 0;
        padding-bottom: 0px;
        font-size: 14px;
        color:#000;
        width: 100%;
    }
    .card-one .bottomdiv span{
        font-size: 18px!important;
    }

    .other{
        min-height: 140px;
        max-height: 140px; 
        margin-bottom: 0px;
    }

    .WTW-card-height {
        height: 170px;
      }

     .WTW-Step-11 .card-body{
        flex: 1 1 auto;
        min-height: 1px;
       padding: 0.5rem; 
      }

      .col-value{
        font-weight: bold;
        font-size: 36px;
      }
      .des-value{
        font-size: 18px;
      }

    .bottomdiv2{
        text-align: center;
        font-weight: bold;
        bottom: 0;
        padding-bottom: 20px;
        font-size: 36px;
        color:#21385C;
        width: 100%;
    }


    .card-general{
        border: none;
        border-radius: 0;
        height: 100%;
        margin-bottom: 15px;
        box-shadow: none;
        /* margin-bottom: 0px; */
    }

    .card-general .card-body{
        flex: 1 1 auto;
        min-height: 1px;
        padding: 0.5rem;
    }

    .card-detail{
        margin-bottom: 15px;
    }
}