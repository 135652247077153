.digital-plant-dashboard {

  .carddgti{
    margin-bottom: 0px!important;
    height: 100% !important;
    box-shadow:none!important;
    border-radius: 1em;
}
.carddgt {
       height: 100% !important;
       border-radius: 0;
       border:1px solid #dee2e6; 
}

.dvdashboard {
  position: relative; 
  top: 0%; 
  left: 0%; 
  width: 100%; 
  height: 100%;

  .cell-icon{
      position: absolute;
      cursor: pointer;
      width: 0.5%;        
      height: 6%;
  }

  .header-icon {
      position: absolute;
      cursor: pointer;
      width:  .8%;        
      height: 1%;
  }

  .green-status {
    background-color: #01ad0f;
    color: #ffffff;

    .highcharts-point {  
     fill: #265634; /* Change the fill color to a specific color */
     stroke: #265634;
    }

    .box {
      background-color: #265634;
      color: #ffffff;
    } 

    .name {
      background-color: #01ad0f !important;
      color: #ffffff;
    }
  }

  .red-status {
    background-color: #c40101;
    color: #ffffff;

    .highcharts-point {  
     fill: #8c0101; /* Change the fill color to a specific color */
     stroke: #8c0101;
    }

    .box {
      background-color: #8c0101;
      color: #ffffff;
    } 

    .name {
      background-color: #c40101 !important;
      color: #ffffff;
    } 
  }

  .yellow-status {
    background-color: #f69708;
    color: #ffffff;

    .highcharts-point {  
     fill: #9b5d01; /* Change the fill color to a specific color */
     stroke: #9b5d01;
    }

    .box {
      background-color: #9b5d01;
      color: #ffffff;
    } 

    .name {
      background-color: #f69708 !important;
      color: #ffffff;
    } 
  }
  

  .gray-status {
    background-color: #989898;
    color: #ffffff;

    .highcharts-point {  
     fill: #5f5b5b; /* Change the fill color to a specific color */
     stroke: #5f5b5b;
    }
  }

  .yellow-blink-status {
    background-color: #f69708;
    color: #ffffff;
    animation: yellowBlink 2s linear infinite;

    .highcharts-point {  
     fill: #9b5d01; /* Change the fill color to a specific color */
     stroke: #9b5d01;
    }
  } 

  .red-blink-status {
    background-color: #c40101;
    color: #ffffff;
    animation: redBlink 2s linear infinite;

    .highcharts-point {  
     fill: #8c0101; /* Change the fill color to a specific color */
     stroke: #8c0101;
    }
  }
  
  .blue-status {
    background-color: #3c48f4;
    color: #ffffff;
    animation: blueBlink 2s linear infinite;

    .highcharts-point {  
     fill: #050b5e; /* Change the fill color to a specific color */
     stroke: #050b5e;
    }

    .box {
      background-color: #050b5e;
      color: #ffffff;
    } 

    .name {
      background-color: #050b5e !important;
      color: #ffffff;
    } 
  }
}
.dvdashboard .imgdvd{
  position: absolute; 
  top: 0; 
  left: 0; 
  right: 0; 
  bottom: 0; 
  margin: auto; 
  min-width: 50%;
  min-height: 50%;
}

.menu-float-container {
  position: absolute;
  top:10;
  display: flex;
  z-index: 1; 
  width: 100%; 
}

.menu-float-bar { 
  background: #fff; 
  /*border-radius: 0.5em;*/
  // border: 1px solid #cdcdcd !important;
  width: 100%; 
} 

.menu-float-bar-button {
  background: transparent !important;
  background-color: transparent !important;
  color: #000000 !important;
}

.menu-float-bar-button:hover {
  background: transparent !important;
  background-color: transparent !important;
  color: #333333 !important;
}

.tdpadding{
  border-bottom: 1px solid #cdcdcd !important;
}

.p-tabview-nav-dv{
  border: 1px solid #dee2e6;
  border-width: 0;
}

.p-tabview-nav-dv li{
  margin-right: 0;
}

.p-tabview-nav-dv li .p-tabview-nav-link{
  // font-weight: 600;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  text-transform: uppercase;
  font-size: 22px;
  margin: 10px;
}


.p-tabview-nav-dv .p-tabview-selected{
  background: #112c45!important;
  color: #fff!important;
  // border: 2px solid #ffffff;
  border-top: 0px;
  //  border-bottom-color: #d0333a !important;
}

.p-tabview-nav-dv .p-unselectable-text{
  // background: #e0e0e0;
  // border: 2px solid #ffffff;
  border-top: 0px;
}
.p-tabview-nav-dv li .p-tabview-nav-link{
  padding: 0!important;
  font-weight: 600;
  height: 20px;
}

.col-description-title{
  overflow: hidden;
text-overflow: ellipsis;
-webkit-line-clamp: 1;
display: -webkit-box;
-webkit-box-orient: vertical;
}

.carddgt-card-body {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding:0px!important;
}

.p-tabview .p-tabview-panels{
    background: #ffffff;
    padding: 0; 
    border: 0 none;
    color: #495057;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.title-ActionPlan-Sub{
  color:#BE1167 !important;
}
.title-ProjectActivities-Sub{
  color:#ff8248 !important;
}
.cursor{
  cursor: pointer;
}
.score-square-ScoreCardInfo {
  color:#64D5B9 !important;
  font-weight: bold;
}
.score-square-FocusReporInfo {
  color:#19A0D6 !important;
  font-weight: bold;
}
.fa-color-star {
  color: #55DD5A;
}
.fa-color-circle {
  color: #FF454D;
}
.fa-color-triangle {
  color: #FFC11A;
}
.fa-color-chart-line {
  color: #A188E7;
}
.fa-color-chart-line-down {
  color: #CC6780;
}
.score-square-default-gradient-past {
  background-color: #808080 !important;
  color: #F6F5FB !important; 
  background: linear-gradient(50deg, #808080 0%, #808080 88%);
}
.score-square-default-gradient-post {
  background-color: #E5E4E2 !important;
  color: #F6F5FB !important; 
  background: linear-gradient(50deg, #E5E4E2 0%, #E5E4E2 88%);
}

.action-plan-summary {
  max-height: 400px;
  overflow: auto;
}

.imgpoint{
  width: calc(2% - 15px);
  height: auto;
}

.imgpoint_no_signal{
  width: calc(2% - 20px);
  height: auto;
}

.tblrow {
  border-collapse: separate;
  border-spacing: 0;
  background-color: #9CC0F8;
  color: #414141!important;
  font-weight: 400;
}

.tblrow thead tr{
  background-color: #4589f4;
  color: #FFF!important;
}



}

.cell-card {
  background-color: #e9e9e9;
  
  .card-body {
    padding: .5rem;
  }

  .card-footer {
    background-color: #e9e9e9;
    border: 0px;
  }
 
  .card-cell-name {
    height: 50px; 
    font-size: 20px; 
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  .green-status {
    background-color: #01ad0f;
    color: #ffffff;

    .highcharts-point {  
     fill: #265634; /* Change the fill color to a specific color */
     stroke: #265634;
    }

    .box {
      background-color: #265634;
      color: #ffffff;
    } 

    .name {
      background-color: #01ad0f !important;
      color: #ffffff;
    }
  }

  .red-status {
    background-color: #c40101;
    color: #ffffff;

    .highcharts-point {  
     fill: #8c0101; /* Change the fill color to a specific color */
     stroke: #8c0101;
    }

    .box {
      background-color: #8c0101;
      color: #ffffff;
    } 

    .name {
      background-color: #c40101 !important;
      color: #ffffff;
    } 
  }

  .yellow-status {
    background-color: #f69708;
    color: #ffffff;

    .highcharts-point {  
     fill: #9b5d01; /* Change the fill color to a specific color */
     stroke: #9b5d01;
    }

    .box {
      background-color: #9b5d01;
      color: #ffffff;
    } 

    .name {
      background-color: #f69708 !important;
      color: #ffffff;
    } 
  }
  
  

  .gray-status {
    background-color: #989898;
    color: #ffffff;

    .highcharts-point {  
     fill: #5f5b5b; /* Change the fill color to a specific color */
     stroke: #5f5b5b;
    }
  }

  @keyframes blueBlink {
    0% {
      background-color: #5f5b5b
    }
    50% {
      background-color: #3c48f4;
    }
    100% {
      background-color: #050b5e;
    }
  }

  @keyframes yellowBlink {
    0% {
      background-color: #5f5b5b
    }
    50% {
      background-color: #f69708;
    }
    100% {
      background-color: #9b5d01;
    }
  }

  @keyframes redBlink {
    0% {
      background-color: #5f5b5b
    }
    50% {
      background-color: #c40101;
    }
    100% {
      background-color: #9b5d01;
    }
  }

  .yellow-blink-status {
    background-color: #f69708;
    color: #ffffff;
    animation: yellowBlink 2s linear infinite;

    .highcharts-point {  
     fill: #9b5d01; /* Change the fill color to a specific color */
     stroke: #9b5d01;
    }
  }

  .red-blink-status {
    background-color: #c40101;
    color: #ffffff;
    animation: redBlink 2s linear infinite;

    .highcharts-point {  
     fill: #8c0101; /* Change the fill color to a specific color */
     stroke: #8c0101;
    }
  }
  
  .blue-status {
    background-color: #3c48f4;
    color: #ffffff;
    animation: blueBlink 2s linear infinite;

    .highcharts-point {  
     fill: #050b5e; /* Change the fill color to a specific color */
     stroke: #050b5e;
    } 
  }


  .card-assets-title {
    height: 50px; 
    font-size: 20px; 
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: #acaeb1;
    color: #ffffff;
  }
 
  .assets-container{ 
    border: 1px solid transparent;
    background-color: #d3d3d3;
    height: 100%;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  
  .cell-header-title-skeleton {
    height: 50px; 
    font-size: 20px; 
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #acaeb1;
    color: #ffffff;
  }

  .cell-header-container-skelethon { 
    border: 1px solid transparent;
    background-color: #d3d3d3;
    height: 100%;    
  }

  .assets-list {
    margin: 5px;
  }

  .asset {
    height: 60px; 
    font-size: 20px;  
    text-align: center; 
    // margin-top: 7px;
    display: flex;

    .box {
      flex: 1;
      flex-basis: 0; /* Esto asegura que tengan el mismo ancho */ 
      // padding: 10px;
      // margin: 5px;
      height: 60px;
      text-align: center;

      .title{
        font-size: 10px;
      }

      .resultado {
        font-size: 13px;
        font-weight: bold;
      }
    } 
  }
  
  .card-cell-sub-header {
    display: flex;

    .box {
      flex: 1;
      flex-basis: 0; /* Esto asegura que tengan el mismo ancho */ 
      // padding: 10px;
      // margin: 5px;
      height: 60px;
      text-align: center;

      .title{
        font-size: 10px;
      }

      .resultado {
        font-size: 15px;
        font-weight: bold;
      }
    }

    
  }

  .card-cell-sub-header-skeleton {
    display: flex;
  }

  .card-cell-container {
    height: 200px;   
    text-align: center;
    padding-top: 20px;
    color: #ffffff;

    .resultado {
      font-size: 60px;
      font-weight: bold;
      line-height: 60px;
    } 
    
    .leyenda-resultado { 
      font-size: 25px; 
    }

    .leyenda-clave-producto {
      font-weight: bold;
    }

    .leyenda-boxes-message { 

      
    }
  }

  .card-cell-boxes-by-time-frame {
    height: 80px; 
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    .chart-title {
      text-align: center;
    }

    .box-production-chart {
    }
  }
}