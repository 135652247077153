.alert_title {
    font-weight: bold;
    font-size: 0.858rem !important;
    color: black;
} 

.alert_descripcion { 
    font-size: 0.858rem !important;
    color: #7a7a84;
}

.alert_date {
    font-weight: bold;
    font-size: 0.858rem !important;
    color: #0f7a8a;
}

.alert_notification { 
    border-bottom: 1px solid #E4E4E4;
}

.alert_notification_last{
    padding: 4px;
}

.alert_panel {
    overflow:auto; 
    max-height: 400px;
    border-radius: 0px;
}

.alert_notifications_summary {
    font-size: 0.858rem !important;
}
 
.bell-ring-animation {
    animation: animated_div 5s 1;
    animation-duration: 5s;
    animation-timing-function: ease;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: animated_div;
  }
   
  
  @keyframes animated_div {
    from {color: red !important;}
    to {color: yellow !important;}
  }
  