
.swal-icon.swal-custom-letter {
    display: flex;
    border-color: #c9dae1;
    color: #87adbd;
  }
  
  .swal-icon.swal-custom-letter .swal-icon-content {
    display: flex;
    align-items: center;
    font-size: 3.75em;
    padding-left: 25px;
  }