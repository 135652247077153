.card-header-dark-green{
    background: #276f0e  !important; 
    padding-left: 10px !important;
    padding-top: 3px !important;
    color: #ffffff !important; 
}
.card-header-success{
    background: #28a745  !important; 
    padding-left: 10px !important;
    padding-top: 3px !important;
    color: #ffffff !important; 
}
.card-header-primary{
    background: #007bff  !important; 
    padding-left: 10px !important;
    padding-top: 3px !important;
    color: #ffffff !important; 
}
.card-header-info{
    background: #17a2b8  !important; 
    padding-left: 10px !important;
    padding-top: 3px !important;
    color: #ffffff !important; 
}
.card-header-danger{
    background: #dc3545  !important; 
    padding-left: 10px !important;
    padding-top: 3px !important;
    color: #ffffff !important; 
}
.card-header-warning{
    background: #ffc107  !important; 
    padding-left: 10px !important;
    padding-top: 3px !important;
    color: #ffffff !important; 
} 
.card-header-orange{
    background: #ff8248  !important; 
    padding-left: 10px !important;
    padding-top: 3px !important;
    color: #ffffff !important; 
} 
.card-header-light-purple{
    background: #3f51b5  !important; 
    padding-left: 10px !important;
    padding-top: 3px !important;
    color: #ffffff !important; 
} 