.score-square-continer { 
    width: auto;
  }  

.score-square {
    height: 49px;
    width: 49px;
    border-radius: 4%;
    font-size: 16px;
    font-weight: bold;
    font-weight:  900 !important;
  } 
  
  .score-square-annual {
    height: 59px;
    width: 137px;
    border-radius: 16px;
    font-size: 16px;
    font-weight: bold;
    font-weight:  900 !important;
  } 
  
  .score-square-month-result { 
    border-radius: 16px;
  }
  .score-square-title {
    font-size: 8px;
  }
  
  .score-square-blue-gradient {
    background-color: #4589f4 !important;
    color: #ffffff !important; 
    background: linear-gradient(50deg, #4589f4 0%, #6da4f6 88%);
  }


  .score-square-default-gradient {
    background-color: #F1F1FA !important;
    color: #333333 !important; 
    background: linear-gradient(50deg, #F6F5FB 0%, #F1F1FA 88%);
  }


  .score-square-green-gradient {
    background-color: #3ad478 !important;
    color: #333333 !important; 
    background: linear-gradient(50deg, #3ad478 0%, #87ef7e 88%);
  }

  .score-square-yellow-gradient {
    background-color: #ffbc00 !important;
    color: #333333 !important; 
    background: linear-gradient(50deg, #ffbc00 0%, #ffe300 88%);
  }

  .score-square-red-gradient {
    background-color: #f35f3e !important;
    color: #333333 !important; 
    background: linear-gradient(50deg, #f35f3e 0%, #f88474 88%);
  }
  
  // .score-cursor-square {
  //     cursor: default !important;
  // }

.score-square-mini {
  height: 35px;
  width: 35px;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: bold !important;
  padding-top: 8px;  
}
 .title-ActionPlan{
    color:#BE1167 !important;
    font-weight: bold;
 }
 .title-ProjectActivities{
    color:#ff8248 !important;
    font-weight: bold;
 }
 .score-square-annualinfo {
  color:#435470 !important;
  font-weight: bold;
}
.score-square-annualinfo-scorecard {
  color:#00cc85 !important;
  font-weight: bold;
}
.score-square-annualinfo-actionplan {
  color:#BE1167 !important;
  font-weight: bold;
}
.score-square-annualinfo-activities{
  color:#ff8248 !important;
  font-weight: bold;
}
.score-square-annualinfo {
  color:#435470 !important;
  font-weight: bold;
}
.title-ActionPlan-Sub{
  color:#BE1167 !important;
}
.title-ProjectActivities-Sub{
  color:#ff8248 !important;
}
.cursor{
  cursor: pointer;
}
.score-square-ScoreCardInfo {
  color:#64D5B9 !important;
  font-weight: bold;
}
.score-square-FocusReporInfo {
  color:#19A0D6 !important;
  font-weight: bold;
}
.fa-color-star {
  color: #55DD5A;
}
.fa-color-circle {
  color: #FF454D;
}
.fa-color-triangle {
  color: #FFC11A;
}
.fa-color-chart-line {
  color: #A188E7;
}
.fa-color-chart-line-down {
  color: #CC6780;
}
.score-square-default-gradient-past {
  background-color: #808080 !important;
  color: #F6F5FB !important; 
  background: linear-gradient(50deg, #808080 0%, #808080 88%);
}
.score-square-default-gradient-post {
  background-color: #E5E4E2 !important;
  color: #F6F5FB !important; 
  background: linear-gradient(50deg, #E5E4E2 0%, #E5E4E2 88%);
}

.action-plan-summary {
  max-height: 400px;
  overflow: auto;
}