.production-dashboard-zone {
    background-color: #12A9AD !important;
    color: #ffffff !important;
}

.production-dashboard-cell {
    background-color: #6c757d !important;
    color:#ffffff !important;
}

.production-dashboard-card-body {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.production-average-by-week {
    padding: 5px;
    text-align: center; 
}

.production-current-week {
    padding: 5px;
    text-align: center; 
}

.resultado-utilizacion {
    font-size: 18px;
    font-weight: bold;
}

.production-dashboard-row {
    border-bottom: 1px dashed lightgray;
}