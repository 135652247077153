.newsletter-loading {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 10px solid #ddd;
    border-top-color: #cf3339;
    animation: newsletter-loading 1s linear infinite;
    position: absolute;
    top: 40%;
    left: 40%;
  }

  @keyframes newsletter-loading {
    to {
      transform: rotate(360deg);
    }
  }

  .newsletter-thumbnail {
    width: 100%;
    display: block;
    border: 1px solid gray;
    border-radius: 10px;
    // box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  }

  .newsletter-loading-container {
    position: relative;
    top: 0;
    left: 0;
  }