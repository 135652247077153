.Tablet-Field-Read-Only {
    padding: 10px;
    border: 1px dotted #4B5563;
    margin-bottom: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .tablet-label-span {
    color: black;
    font-weight: bold;
    }
.tablet-label-content {
  color: #112C45;
  }
  .tablet-label{
    font-weight: bold;
    color: #112C45;
  }
  .tablet-header-main{
    background-color: #112C45;
    color: white;
    margin-left: 0px; 
    margin-right: 0px;
  }
  .tablet-header-label{    
    color: white;
  }
  .tablet-header-label-value{    
    color: white;
    font-weight: bold;
  }

   .tablet-header-cell-number {
    font-size: 35px;
    font-weight: bold;
   }

  .bgtablet-header {  
    background: #ffffff !important;
    border-bottom: 1px solid #e5e5e5 !important;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%); 
    padding: 5px;
    padding-top: 13px;
  }

  .tablet-icon-position-bottom-left{
    position: fixed;
    bottom: 40px;
    left: 25px;
    z-index: 20;
  }

  .tablet-icon-position-bottom-right{
    position: fixed;
    bottom: 40px;
    right: 25px;
    z-index: 20;
  }

  .tablet-icon-style{ 
    border: 2px solid #fff;
    border-radius: 50%;
    height: 50px !important;
    width: 50px !important;
    color: #fff;
    cursor: pointer;
    animation: movebtnh 3s ease-in-out infinite;
    transition: all .5s ease-in-out;
  }
  .tablet-icon-style:hover{
    animation: none;
    background: #fff;
    color: #551B54;
    border: 2px solid #551B54;
  }

  @keyframes movebtnh {
    0%{
      transform: translateY(0px);
    }
    25%{
      transform: translateY(10px);
    }
    50%{
      transform: translateY(0px);
    }
    75%{
      transform: translateY(-10px);
    }
    100%{
      transform: translateY(0px);
    }
  }