.infoNeedsToBeRefreshCard { 
    border-radius: 1em;
    background-color: #d9e0e3;
    color: #526a76; 
    width: 100%;
    height: auto;
    padding: 5px;
    text-align: center; 
  }
   
.infoNeedsToBeRefreshCard-legend {
    font-size: 18px;
    margin: 15px;
    margin-top: 7px;
}

  .refreshSizeIcon {
    font-size: 45px;
    margin: 20px;
  }

  .infoNeedsToBeRefreshCard-button {
    font-size: 18px; 
    margin: 15px;
    background-color: #125386;
    border: 0px;
    padding: 15px;
}

.infoNeedsToBeRefreshCard-button:hover { 
  border: 0px; 
}