.ticket-selection:hover {
    background-color: lightgray;
}

.ticket-selection {
    border-radius: 10px;
    border: 2px solid black;
    padding: 20px;
    cursor: pointer;
}

.ticket-selection-title {
    font-weight: bold;
}