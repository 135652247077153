.score-circle {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    font-size: 16px;
    font-weight: bold;
    font-weight:  900 !important; 
  }
  
  .score-container {
    width: auto;
  }
  
  .score-circle-badge {
    padding-left: 10px;
    padding-right: 10px;
    background-color: #ffffff;
    border: 1px solid #999999;
    color: #999999;
    font-weight: bold;
  } 

  
  
  .score-title {
    font-size: 11px;
  }

  .score-blue-gradient {
    background-color: #4589f4 !important;
    color: #ffffff !important; 
    background: linear-gradient(50deg, #4589f4 0%, #6da4f6 88%);
  }

  .score-darkBlue-gradient {
    background-color: #283346 !important;
    color: #ffffff !important; 
    background: linear-gradient(50deg, #283346 0%, #506382 88%);
  }
  


  .score-default-gradient {
    background-color: #ffffff !important;
    color: #333333 !important; 
    background: linear-gradient(50deg, #cccccc 0%, #cccccc 88%);
  }


  .score-green-gradient {
    background-color: #3ad478 !important;
    color: #333333 !important; 
    background: linear-gradient(50deg, #3ad478 0%, #87ef7e 88%);
  }

  .score-yellow-gradient {
    background-color: #ffbc00 !important;
    color: #333333 !important; 
    background: linear-gradient(50deg, #ffbc00 0%, #ffe300 88%);
  }

  .score-red-gradient {
    background-color: #f35f3e !important;
    color: #333333 !important; 
    background: linear-gradient(50deg, #f35f3e 0%, #f88474 88%);
  }

  .score-cursor {
      cursor: default !important;
  }

  

.score-circle-mini {
  height: 35px;
  width: 35px;
  border-radius: 50% !important;
  font-size: 12px !important;
  font-weight: bold !important;
  padding-top: 8px;
}
 