.SalesDashboardScreen {
  
  .DashboardDropdownCategory {
    border: 0px !important;
    font-weight: bold;
    font-size: 16px;
    color: #333333 !important;
  }

  .DashboardDropdownCategory>span { 
    font-weight: bold;
    font-size: 16px;
    color: #333333 !important;
  }

  .small {
    font-size: 80%;
  }

  .WTW-card-height {
    height: 140px;
  }
  
  .WTW-card-medium-height {
    height: 350px;
  }

  .col-title{
    padding-top: 5px;
    font-weight: bold;
    font-size: 16px;
  }

  .col-subtitle { 
    font-size: 12px;
    font-weight: bold;
  }

  .col-description{
    font-size: 1rem;
  }

  .col-description-small{
    font-size: 0.8rem;
    color:"#CDCDCD"!important;
  }

  .col-description-title{
    font-size: 1rem;
    color:"#CDCDCD"!important;
  }

  .col-value{
    font-weight: bold;
    font-size: 22px;
  }

   .card-footer {
       
   }

   .card-body { 
    
   }

   .p-datatable .p-datatable-tfoot > tr > td {
    padding: 0px !important;
   }

   .fieldbutton-dashboard {

        &.p-selectbutton {
            
            .p-button {
              height: 20px;
            }

            .p-button.p-highlight {
                background: #689f38;
                border-color: #689f38;
                color: #ffffff;
            }

        }

    }


   /*
   .p-datatable{
    border-radius: 7px 7px 7px 7px;
    border: none;
    overflow: hidden;
   }
 
.p-datatable .p-datatable-tfoot > tr > td{
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #fff;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #495057;
  background: #fff;
}

tbody td, thead th{
    padding: none; 
}

.p-datatable .p-datatable-thead > tr > th{
  background: #fff;
  color: #333 !important;
  padding: 4px 4px 4px 4px !important;
}
*/

}


.tour-info {
  font-size: 12px;
 }