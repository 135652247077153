.dateRangeWrapper {
    position: relative;
}

.dateRangePanel {
    position: absolute;
    z-index: 90;
    // width: 100%;
    top: 35px;  
    box-shadow: 0 0 8px rgba(0, 0, 0, .35);
    border-radius: 1em; 
    --overlayArrowLeft:0px;
    background-color: #FFFFFF;
}

.dateRangePanel:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 0 8px 16px;
    border-color: #FFFFFF transparent;
    display: block;
    width: 0;
    z-index: 91;
    margin-left: -8px;
    top: -16px;
    left: 50%;
}
  
.dateRangePanel:before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 0 9px 17px;
    border-color: #dee2e6 transparent;
    display: block;
    width: 0;
    z-index: 91;
    margin-left: -9px;
    top: -19px;
    left: 50%;
}