.CompanyOverviewOverallStatusChartSkeleton {

    .CompanyOverviewOverallStatusChartSkeleton-status {

        &.p-skeleton {
            margin-left: auto !important;
            margin-right: auto !important;
        }
    }

    .CompanyOverviewOverallStatusChart-label {
        padding: unset;

        span {
            font-size:xx-small;        
        }        
    }
}