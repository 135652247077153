.li-group {
    position: relative;
    display: flex;
    /* flex-wrap: wrap; */
    align-items: center;
    width: 100%;
  }
  
  .li-group-item {
    font-size: 15px; 
    font-weight: 600;
    color: gray;
  }

  .li-group-button{
      cursor: pointer; 
  }

  .li-group-button :hover{ 
    text-decoration: underline;
    font-weight: bold;
}