.p-galleria-item-nav {
    // background-color: #2196F3 !important;
    background-color: rgb(6, 42, 88) !important;
}

.p-galleria-thumbnail-container {
    background-color: rgb(6, 42, 88) !important;
    justify-content: center;
}

.p-galleria-item-wrapper {
    margin-bottom: 0.1em;
}