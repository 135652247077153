
 
.pdf-controls {
    position: relative;
}
.pdf-controls-container { 
    display: flex; 
    padding-right: 2%;
    padding-bottom: 0%;
}


.pdf-controls button {
    width: 1.75em;
    height: 1.75em;
    font-size: 18px;
    border-radius: 100%; 
    border: 1px solid lightgrey; 
}

.pdf-controls button:focus {
    outline: none;
    box-shadow: 0 0 3px 2px #b2f2ff;
}  

.page-list > div:not(:last-child) {
    margin-bottom: 15px;
}

.page-list .ReactPDF__Page {
    display: inline-block;
}
 

.pdf-container {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 15px;
    position: relative; 
} 

.pdf-container .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
} 

.pdf-viewer-dialog .p-dialog-content  {
        // overflow-y: auto !important; 
        background-color: #e0e0e0;
        height: 80vh;
}

.pdf-viewer-card .card-body {
    // overflow-y: auto !important; 
    background-color: #e0e0e0;
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
    height: 80vh;
}