.p-datatable ::-webkit-scrollbar {
    background-color: #fff !important;
}

.p-datatable { 
    border-radius: 7px 7px 7px 7px;
    border: 1px solid #ccc; 
    overflow: hidden;
} 
 

.p-datatable .p-datatable-header{ 
border-width: 0; 
}

.p-datatable .p-datatable-thead > tr > th {
border: 0px !important;
font-size: 11px;
}

.p-datatable .p-datatable-header {
    background: #20d891;
    color: #ffffff;
    padding: 4px 14px 4px 14px !important;
    border-radius: 7px 7px 0px 0px;
  }
  

.p-datatable .p-datatable-thead > tr > th { 
    background: #435470 !important;
    color: #ffffff !important; 
    padding: 4px 4px 4px 4px !important;
}

.p-datatable .p-datatable-scrollable-header{
    background: #435470;
    color: #ffffff !important;
}
/*
.p-paginator { 
    border-radius: 0px 0px 4px 4px;
    background-color: #f8f9fa; 
}

.p-datatable .p-paginator-bottom {
    border-radius: 0px 0px 7px 7px; 
}
*/
/*
.p-datatable .p-datatable-tfoot > tr > td {
    padding: 0px;
}
*/
/*
.p-datatable .p-datatable-header {
    border-width: 0;
    border-radius: 7px 7px 0px 0px;
}

.p-datatable .p-datatable-scrollable-header { 
    border-radius: 7px 7px 0px 0px;
}
*/

/* DARK GREEN */
.table-header-background-dark-green .p-datatable-thead > tr > th {
    background-color: #019e8a; 
    /* background: linear-gradient(50deg, #019e8a 0%, #3ad478 88%) !important; */
    color: #ffffff !important;
} 

.table-header-background-dark-green.p-datatable .p-datatable-scrollable-header{
    background-color: #019e8a; 
    /* background: linear-gradient(50deg, #00ba91 0%, #3ad478 88%) !important; */
    color: #ffffff !important;
}

.p-datatable-thead > tr > th.table-header-background-dark-green  {
    background-color: #019e8a !important; 
    /* background: linear-gradient(50deg, #019e8a 0%, #3ad478 88%) !important; */
    color: #ffffff !important;
}


.table-header-background-dark-green .p-paginator {
    background-color: #ccf1e9; 
    /* background: linear-gradient(50deg, #00ba91 0%, #3ad478 88%) !important; */
    color: #ffffff !important;
    border-radius: 0px 0px 4px 4px;
}

.table-header-background-dark-green  > .p-datatable-header  {
    background-color: #ccf1e9; 
    /* background: linear-gradient(50deg, #019e8a 0%, #3ad478 88%) !important; */
    /* color: var(--text-color); */
} 
 
.p-datatable-thead > tr > th.table-header-background-dark-green.border-right  {
    border-right: 1px solid #019e8a !important;  
}

.p-datatable-tbody > tr > td.table-header-background-dark-green.border-right  {
    border-right: 1px solid #019e8a !important;  
}

.table-header-background-dark-green .p-datatable-tfoot > tr > td {
    background-color: #ccf1e9; 
    padding: 4px;
}

.table-header-background-dark-green .p-datatable-scrollable-footer {
    background-color: #ccf1e9; 
}

 
/* LIGHT GREEN */ 

.table-header-background-ligth-green .p-datatable-thead > tr > th {
    background-color: #00ba91; 
    /* background: linear-gradient(50deg, #019e8a 0%, #3ad478 88%) !important; */
    color: #ffffff !important;
}

.table-header-background-ligth-green.p-datatable .p-datatable-scrollable-header{
    background-color: #00ba91; 
    /* background: linear-gradient(50deg, #00ba91 0%, #3ad478 88%) !important; */
    color: #ffffff !important;
}

.p-datatable-thead > tr > th.table-header-background-ligth-green  {
    background-color: #00ba91 !important; 
    /* background: linear-gradient(50deg, #019e8a 0%, #3ad478 88%) !important; */
    color: #ffffff !important;
}


.table-header-background-ligth-green .p-paginator {
    background-color: #ccf1e9; 
    /* background: linear-gradient(50deg, #00ba91 0%, #3ad478 88%) !important; */
    color: #ffffff !important;
    border-radius: 0px 0px 4px 4px;
}

.table-header-background-ligth-green  > .p-datatable-header  {
    background-color: #00ba91; 
    /* background: linear-gradient(50deg, #019e8a 0%, #3ad478 88%) !important; */
    /* color: var(--text-color); */
} 

.p-datatable-thead > tr > th.table-header-background-ligth-green.border-right  {
    border-right: 1px solid #00ba91 !important;  
}

.p-datatable-tbody > tr > td.table-header-background-ligth-green.border-right  {
    border-right: 1px solid #00ba91 !important;  
}

.table-header-background-ligth-green .p-datatable-tfoot > tr > td {
    background-color: #ccf1e9; 
    padding: 4px;
}

.table-header-background-ligth-green .p-datatable-scrollable-footer {
    background-color: #ccf1e9; 
}

/* GREEN */
.table-header-background-green .p-datatable-thead > tr > th {
    background-color: #3ad478; 
    /* background: linear-gradient(50deg, #019e8a 0%, #3ad478 88%) !important; */
    color: #ffffff !important;
}

.table-header-background-green.p-datatable .p-datatable-scrollable-header{
    background-color: #3ad478; 
    /* background: linear-gradient(50deg, #00ba91 0%, #3ad478 88%) !important; */
    color: #ffffff !important;
}

.p-datatable-thead > tr > th.table-header-background-green  {
    background-color: #3ad478 !important; 
    /* background: linear-gradient(50deg, #019e8a 0%, #3ad478 88%) !important; */
    color: #ffffff !important;
}


.table-header-background-green .p-paginator {
    background-color: #b1fccf; 
    /* background: linear-gradient(50deg, #00ba91 0%, #3ad478 88%) !important; */
    color: #ffffff !important;
    border-radius: 0px 0px 4px 4px;
}

.table-header-background-green  > .p-datatable-header  {
    background-color: #3ad478; 
    /* background: linear-gradient(50deg, #019e8a 0%, #3ad478 88%) !important; */
    /* color: var(--text-color); */
} 

.p-datatable-thead > tr > th.table-header-background-green.border-right  {
    border-right: 1px solid #3ad478 !important;  
}

.p-datatable-tbody > tr > td.table-header-background-green.border-right  {
    border-right: 1px solid #3ad478 !important;  
}

.table-header-background-green .p-datatable-tfoot > tr > td {
    background-color: #b1fccf; 
}

.table-header-background-green .p-datatable-scrollable-footer {
    background-color: #b1fccf; 
    padding: 4px;
}

/* RED */ 

.table-header-background-red .p-datatable-thead > tr > th {
    background-color: #f35f3e; 
    /* background: linear-gradient(50deg, #019e8a 0%, #3ad478 88%) !important; */
    color: #ffffff !important;
}

.table-header-background-red.p-datatable .p-datatable-scrollable-header{
    background-color: #f35f3e; 
    /* background: linear-gradient(50deg, #00ba91 0%, #3ad478 88%) !important; */
    color: #ffffff !important;
}

.p-datatable-thead > tr > th.table-header-background-red  {
    background-color: #f35f3e !important; 
    /* background: linear-gradient(50deg, #019e8a 0%, #3ad478 88%) !important; */
    color: #ffffff !important;
}


.table-header-background-red .p-paginator {
    background-color: #faaa98; 
    /* background: linear-gradient(50deg, #00ba91 0%, #3ad478 88%) !important; */
    color: #ffffff !important;
    border-radius: 0px 0px 4px 4px;
}

.table-header-background-red  > .p-datatable-header  {
    background-color: #f35f3e; 
    /* background: linear-gradient(50deg, #019e8a 0%, #3ad478 88%) !important; */
    /* color: var(--text-color); */
} 

.p-datatable-thead > tr > th.table-header-background-red.border-right  {
    border-right: 1px solid #f35f3e !important;  
}

.p-datatable-tbody > tr > td.table-header-background-red.border-right  {
    border-right: 1px solid #f35f3e !important;  
}

.table-header-background-red .p-datatable-tfoot > tr > td {
    background-color: #faaa98; 
    padding: 4px;
}

.table-header-background-red .p-datatable-scrollable-footer {
    background-color: #faaa98; 
}
 
/* YELLOW */ 

.table-header-background-yellow .p-datatable-thead > tr > th {
    background-color: #ffbc00; 
    /* background: linear-gradient(50deg, #019e8a 0%, #3ad478 88%) !important; */
    color: #ffffff !important;
}

.table-header-background-yellow.p-datatable .p-datatable-scrollable-header{
    background-color: #ffbc00; 
    /* background: linear-gradient(50deg, #00ba91 0%, #3ad478 88%) !important; */
    color: #ffffff !important;
}

.p-datatable-thead > tr > th.table-header-background-yellow  {
    background-color: #ffbc00 !important; 
    /* background: linear-gradient(50deg, #019e8a 0%, #3ad478 88%) !important; */
    color: #ffffff !important;
}


.table-header-background-yellow .p-paginator {
    background-color: #ffe395; 
    /* background: linear-gradient(50deg, #00ba91 0%, #3ad478 88%) !important; */
    color: #ffffff !important;
    border-radius: 0px 0px 4px 4px;
}

.table-header-background-yellow  > .p-datatable-header  {
    background-color: #ffbc00; 
    /* background: linear-gradient(50deg, #019e8a 0%, #3ad478 88%) !important; */
    /* color: var(--text-color); */
} 

.p-datatable-thead > tr > th.table-header-background-yellow.border-right  {
    border-right: 1px solid #ffbc00 !important;  
}

.p-datatable-tbody > tr > td.table-header-background-yellow.border-right  {
    border-right: 1px solid #ffbc00 !important;  
}

.table-header-background-yellow .p-datatable-tfoot > tr > td {
    background-color: #ffe395; 
    padding: 4px;
}

.table-header-background-yellow .p-datatable-scrollable-footer {
    background-color: #ffe395; 
}

/* light-blue */ 
/*
.table-header-background-light-blue { 
    border-radius: 7px 7px 7px 7px;
    border: 1px solid #ccc; 
    overflow: hidden;
} 
*/

.table-header-background-light-blue .p-datatable-thead > tr > th {
    background-color: #1fa3da; 
    /* background: linear-gradient(50deg, #019e8a 0%, #3ad478 88%) !important; */
    color: #ffffff !important;
}

.table-header-background-light-blue.p-datatable .p-datatable-scrollable-header{
    background-color: #1fa3da; 
    /* background: linear-gradient(50deg, #00ba91 0%, #3ad478 88%) !important; */
    color: #ffffff !important;
}

.p-datatable-thead > tr > th.table-header-background-light-blue  {
    background-color: #1fa3da !important; 
    /* background: linear-gradient(50deg, #019e8a 0%, #3ad478 88%) !important; */
    color: #ffffff !important;
}


.table-header-background-light-blue .p-paginator {
    background-color: #d2eef9; 
    /* background: linear-gradient(50deg, #00ba91 0%, #3ad478 88%) !important; */
    color: #ffffff !important; 
}

.table-header-background-light-blue  > .p-datatable-header  {
    background-color: #1fa3da; 
    /* background: linear-gradient(50deg, #019e8a 0%, #3ad478 88%) !important; */
    /* color: var(--text-color); */
} 
 
.p-datatable-thead > tr > th.table-header-background-light-blue.border-right  {
    border-right: 1px solid #1fa3da !important;  
}

.p-datatable-tbody > tr > td.table-header-background-light-blue.border-right  {
    border-right: 1px solid #1fa3da !important;  
}

.table-header-background-light-blue .p-datatable-tfoot > tr > td {
    background-color: #d2eef9; 
    padding: 4px;
}

.table-header-background-light-blue .p-datatable-scrollable-footer {
    background-color: #d2eef9; 
}
/* blue */  

.table-header-background-blue .p-datatable-thead > tr > th {
    background-color: #455471; 
    /* background: linear-gradient(50deg, #019e8a 0%, #3ad478 88%) !important; */
    color: #ffffff !important;
}

.table-header-background-blue.p-datatable .p-datatable-scrollable-header{
    background-color: #455471; 
    /* background: linear-gradient(50deg, #00ba91 0%, #3ad478 88%) !important; */
    color: #ffffff !important;
}

.p-datatable-thead > tr > th.table-header-background-blue  {
    background-color: #455471 !important; 
    /* background: linear-gradient(50deg, #019e8a 0%, #3ad478 88%) !important; */
    color: #ffffff !important;
}


.table-header-background-blue .p-paginator {
    background-color: #ecf2fe; 
    /* background: linear-gradient(50deg, #00ba91 0%, #3ad478 88%) !important; */
    color: #ffffff !important; 
}

.table-header-background-blue  > .p-datatable-header  {
    background-color: #455471; 
    /* background: linear-gradient(50deg, #019e8a 0%, #3ad478 88%) !important; */
    /* color: var(--text-color); */
} 
 

.p-datatable-thead > tr > th.table-header-background-blue.border-right  {
    border-right: 1px solid #455471 !important;  
}

.p-datatable-tbody > tr > td.table-header-background-blue.border-right  {
    border-right: 1px solid #455471 !important;  
} 

.table-header-background-blue .p-datatable-tfoot > tr > td {
    background-color: #ecf2fe; 
    padding: 4px;
}

.table-header-background-blue .p-datatable-scrollable-footer {
    background-color: #ecf2fe; 
}

/* Orange */  

.table-header-background-orange .p-datatable-thead > tr > th {
    background-color: #ff8248; 
    /* background: linear-gradient(50deg, #019e8a 0%, #3ad478 88%) !important; */
    color: #ffffff !important;
}

.table-header-background-orange.p-datatable .p-datatable-scrollable-header{
    background-color: #ff8248; 
    /* background: linear-gradient(50deg, #00ba91 0%, #3ad478 88%) !important; */
    color: #ffffff !important;
}

.p-datatable-thead > tr > th.table-header-background-orange  {
    background-color: #ff8248 !important; 
    /* background: linear-gradient(50deg, #019e8a 0%, #3ad478 88%) !important; */
    color: #ffffff !important;
}


.table-header-background-orange .p-paginator {
    background-color: #ecf2fe; 
    /* background: linear-gradient(50deg, #00ba91 0%, #3ad478 88%) !important; */
    color: #ffffff !important; 
}

.table-header-background-orange  > .p-datatable-header  {
    background-color: #ff8248; 
    /* background: linear-gradient(50deg, #019e8a 0%, #3ad478 88%) !important; */
    /* color: var(--text-color); */
} 
 

.p-datatable-thead > tr > th.table-header-background-orange.border-right  {
    border-right: 1px solid #ff8248 !important;  
}

.p-datatable-tbody > tr > td.table-header-background-orange.border-right  {
    border-right: 1px solid #ff8248 !important;  
} 

.table-header-background-orange .p-datatable-tfoot > tr > td {
    background-color: #ecf2fe; 
    padding: 4px;
}

.table-header-background-orange .p-datatable-scrollable-footer {
    background-color: #ecf2fe; 
}


.frozen-editable .p-datatable-tbody > tr {
        height: 45px;
} 