.MMADashboardScreen {

    .MMADashboardScreen-data-view {

        &.p-dataview {            

            .p-dataview-content {
                height: 700px;
                max-height: 700px;
            }
        }        
    }    
}