.PhoneInputInput {
    font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.87);
    background: #ffffff;
    padding: 0.75rem 0.75rem;
    border: 1px solid rgba(0, 0, 0, 0.38);
    transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
    appearance: none;
    border-radius: 4px;
}
 
.PhoneInputCountry {
    padding-left: 2px;
}