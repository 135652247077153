.BMTrackingDashboardScreen {
   .link {
     cursor: pointer;
     color: #fff;
     text-decoration: underline;
   }

   .link:hover {
    cursor: pointer;
    color: #fff; 
  }

   .card-footer {
        padding: 10px;
        background-color: #162d47;
   }

   .description {
     font-size: 14px;
   } 

   .card-body { 
    background-image: url("../../../assets/img/midcontinent-M-recortado.png");
    background-repeat: no-repeat; 
    background-position: right top; 
   }
  
   .ban-icon {
    font-size: 20px;
    color: red;
   }
}