.helpCenter-continer { 
    width: auto;
  }  

.helpCenter-preview {
    height: 250px;
    width: 200px;
    border-radius: 4%;
    font-size: 16px;
    font-weight: bold;
    font-weight:  900 !important;
    border: 1px solid #e5e7ea;
    box-shadow: 0 1px 10px 0 #e5e7ea;
    overflow: hidden;
  } 

.helpCenter-thumbnail {
    position:relative;
    width: 100%;
    height: 150px; 
}

.helpCenter-pdf {
    font-size: 140px;
    padding: 4px;
    color: #b30b00;
    text-align: center;
}

.helpCenter-info { 
    width: 100%;
    height: 100px;
    background-color: #f0f2f5;
    position: relative;
    padding: 10px;
    padding-top: 3px;
}

.helpCenter-title {  
    font-size: 12px;
    font-weight: bold;
    color: #343a40;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis; 
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.helpCenter-description { 
    font-size: 11px;
    color: #6c757d; 
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis; 
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.helpCenter-view-detail { 
    font-size: 10px;
    color: #0f7a8a;
    text-align: right;
    margin-right: 5px;
    position: absolute;
    bottom: 3px;
    right: 5px;
}

