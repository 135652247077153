.instrucciones { 
    border-radius: 1em;
    background-color: #1c398d;
    color: #ffffff; 
    width: 100%;
    height: auto;
    padding: 5px;
    text-align: center; 
  }
  
  .highlight-text {
    font-weight: bold;
    font-size: 14px;
  }

  .transparent { 
    border-radius: 1em; 
    width: 100%;
    height: auto;
    padding: 5px; 
    text-align: justify;
    text-justify: inter-word;
  }
  
  .instruccions-legend {
    font-size: 12px;
    color: #5a6882;
    font-weight: bold;
  }