.DAISeerThoughtsPanel {

    .DAISeerThoughtsPanelPanel {

        &.p-panel {

            .p-panel-content {
                border: none !important;
            }
    
        }

    }
    
}