.DailyOperationTrackerScreen {
      
    .card-produccion{
        background-color: #DDEBF7!important;
        border-radius: 0.8em!important;
        padding-top: 10px;
        padding-bottom: 5px;
        text-align: center;
        font-size: 14px;
        box-shadow: 0 0 2px #00000059;
    }

    .card-produccion-congratulation{
        background-color: #e0e0e0!important;
        border-radius: 0.8em!important;
        padding-top: 10px;
        padding-bottom: 5px;
        text-align: center;
        font-size: 14px;
        box-shadow: 0 0 2px #00000059;
    }

    .comment-section{
        background: #fafafa !important;
        background-color: #fafafa !important;
        color:#282529 !important;
    }
}


.scrolleable-card-comments {
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 500px;
}