.TreeCorporateView  {
    border-radius: 7px 7px 7px 7px;
    border: 1px solid #ccc; 
    overflow: hidden;
  }

.TreeCorporateView.dx-treelist .dx-row > td {
     /* */
  }
  .empty-column-space {
    padding-right: 2px !important;
    padding-left: 2px !important;
  }

  .TreeCorporateView .dx-treelist-cell-expandable { 
      background-color: hsla(0,0%,86.7%,.2) !important;
  }

.TreeCorporateView .TreeCorporateView-Total-Column { 
    font-weight: bold;
}
 
.dx-data-row .TreeCorporateView-light-gray-column {
    background: hsla(0,0%,86.7%,.2)  !important;
    background-color: hsla(0,0%,86.7%,.2) !important;
    color: #343a40;
  }

.boldBlack {
  font-weight: bold;
  color: #333333;
}

.boldBlue {
  font-weight: bold;
  color: #0d5293;
}

.TreeListcell {
  width: 100%;
  height: 100%; 
}

 
.TreeCorporateView .dx-treelist-content .dx-treelist-table .dx-row 
> td.dx-cell-modified:not(.dx-field-item-content):not(.dx-validation-pending) { 
  padding: 0px !important;
} 


.TreeCorporateView .dx-treelist .dx-treelist-content .dx-treelist-table .dx-row > td.dx-treelist-invalid:not(.dx-field-item-content):not(.dx-validation-pending):not(.dx-treelist-select-all), .dx-treelist .dx-treelist-content .dx-treelist-table .dx-row 
> td:not(.dx-validation-pending):not(.dx-treelist-select-all) { 
  padding: 0px !important;
} 