
.divMap{
  top:0;
  left: 0; 
}

.cluster-marker {
    color: rgb(255, 255, 255);
    background: #1978c8;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer!important;
  }
  .custom-marker {
    cursor: pointer!important;
  }

  .popover{
    max-width: 300px;
    width: 250px;
    padding: 1px;
    padding-top:20;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.42857143;
    line-break: auto;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    white-space: normal;
    font-size: 14px;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.2);
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
  }
  .popover-title{
    padding: 8px 14px;
    margin: 0;
    font-size: 14px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-radius: 5px 5px 0 0;
    text-align: center;
  }
  .popover-content{
    padding: 9px 14px;
  }

  .planningmap-leyendas {
    position: absolute;
    background-color: #fff;
    right: 30px;
    top: 60px;
    z-index: 100;
  }

  .planning-map-filters {
    background-color: #fff; 
  }

  .planning-content {
    margin-left: 0px;
    margin-right: 0px; 
  }

  @media (min-width: 576px) {
    .planningMapHead {
      display: none;
    }
  }
  @media (max-width: 576px) {
    .planningMap {
      display: none;
    }
  }
