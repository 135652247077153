



.head-organizationalPosition{
    background-color: #112C45;
    border-radius: 3rem !important;
    width: 100%;
    color: #FFFFFF;
    height: 40px !important;
}
.avatar-position{
    margin-left: 10px;
}

.user-name {
    font-size: 14px;
    font-weight: bold;
    width: 100%;
}

.organization-default-width {
    width: 350px;
}

/*
.head-organizationalPosition .p-tree-container {
    max-height: 450px;
}
*/

@media screen and (max-width: 640px) { 
    .organization-default-width {
        width: 250px;
    }
    
}