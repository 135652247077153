.GantryBanner {
    .GantryWarningBanner{
        text-align: right;
        font-size: 2rem;
        padding-top: 7px;
        padding-bottom: 7px;
        height: 30px; 
        position: fixed;
        bottom: 0px;
        width:100%;
        z-index: 1001;
        box-shadow: 0 -4px 8px -4px rgba(128, 128, 128, 0.6);
      } 
      
    .marquee { 
        animation: marquee 10s linear infinite;
    }
    
    @keyframes marquee {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(-100%);
        }
    }
}
